import { useEffect, useState } from "react";
import ResubmissionModal from "@src/component/insurance/common/DocumentResubmission";
import JSZip from 'jszip';
import { markDocApproved, updateLeadDetailsInStore, requestDocResubmission } from '@src/store/slices/insurance';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import secureStorage from "@src/config/encrypt";
import * as HELPER from '@src/config/helper';
import { addDocument } from "@src/store/slices/common";
import { DOCUMENT_CATEGORY, DOC_STATUS, ROLE_FEATURES, STATUS_SUB_STATUS, APP_TYPE_AGENCY , AGENCY_STATUS, DOC_UPLOAD_VALIDATION, LEAD_TYPE_CRM} from "@src/config/constant";
import { validateInstallmentDocs } from "./CommonDocumentFunction";


export default function DocumentGridView(props: any) {
    let { allImages, toggleswitchgrid, callFrom, docType, handleImageClick } = props
    let [selectedImages, setSelectedImages] = useState<any[]>([])
    let [resubmissionModal, setResubmissionModal] = useState<any>(false)
    let [resubmissionComment, setResubmissionComment] = useState<any>(null)
    let [leadId, setLeadId] = useState<any>()
    let [leadSubstatus, setLeadSubstatus] = useState(0)
    let [leadDetails, setLeadDetails] = useState<any>({})


    let authUserInfo = secureStorage.getItem('authUserInfo');
    let userId = authUserInfo && authUserInfo.user_data && authUserInfo.user_data.id

    const dispatch = useDispatch()
    const insuranceState = useSelector((state: any) => state.insurance)

    let editAccess = HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['doc_upload'], accessType: 'edit', assignUserId: `${leadDetails?.assign_user_id}` })
    /**
    * when user clicks on cross icon
    * function to delete image
    * @param element 
    */
    const handleImageDelete = async (element: any) => {
        if (element && Object.keys(element).length) {
            let flag = window.confirm("Confirm, do you want to delete ?")

            if (flag) {
                let validateInstallmentFile: any = validateInstallmentDocs({ value: element.doc_id }, insuranceState?.leadDetail, {})
                if (validateInstallmentFile?.error) {
                    return toast.error(validateInstallmentFile.msg)
                }
                let docPath = [{ id: element.id, document_id: element.doc_id, type: 'delete', document_type_id: +element.doc_type_id }]
                let insertParams = new FormData()
                insertParams.append('lead_id', `${leadId}`)
                insertParams.append('user_id', userId)
                insertParams.append('doc_path', JSON.stringify(docPath))

                let uploadDocument: any = await addDocument(insertParams)

                //if delete success, then remove image from redux
                if (uploadDocument && uploadDocument?.status === 200) {
                    updateLeadDetailInRedux({ documents: element, type: 'delete', doc_status_id: uploadDocument?.data?.doc_status_id })
                    toast.success(`Image deleted Successfully`)
                }
            }
        }
    }

    /**
   * function to handle image checkbox click
   * @param checked 
   * @param images 
   */
    const handleImageSelection = (checked: string, images: any) => {
        if (checked && images) {
            if (images === 'Select-All' && allImages.length) {
                let updatedImage = (allImages && allImages.filter((e: any) => e.is_resubmit !== 2 && e.doc_id !== 0)) || []
                setSelectedImages(updatedImage)
            }
            else {
                let prev = selectedImages && selectedImages.find((e: any) => e.id === images.id)
                if (!prev) {
                    setSelectedImages((prev: any) => {
                        let updatedImages = [...prev, images]
                        return updatedImages
                    })
                }
            }

        }
        if (!checked) {
            if (images && images === 'Select-All') {
                setSelectedImages([])
            }
            else {
                let filteredImages = (selectedImages && selectedImages.filter((e: any) => e.id !== images?.id)) || []
                setSelectedImages(filteredImages)
            }
        }

    }

    /**
     * function to handle various button clicks
     * @param type 
     */
    const handleBtnClick = async (type: string) => {
        if (type === 'approve') {
            //to include all images of selected document category
            let updatedSelectedImages: any = []
            for (let image of selectedImages) {
                //already present
                let isExists = updatedSelectedImages.find((e: any) => e.doc_id === image.doc_id)
                if (!isExists) {
                    let allDocsToApprove = allImages && allImages.filter((e: any) => e.doc_id === image.doc_id)
                    updatedSelectedImages = [...updatedSelectedImages, ...allDocsToApprove]
                }

            }

            markImageApprove(updatedSelectedImages)
        }

        if (type === 'resubmission') {
            requestResubmission(selectedImages)
            hideModalResubmission()
        }

        if (type === 'download') {
            props.setLoader(true)
            if (selectedImages.length) {
                const zip = new JSZip()
                for (let data of selectedImages) {
                    let res = await fetch(data.path)
                    let blob = await res.blob()
                    let name: any = data.path.split('?')
                    let splitName = name?.[0]?.split('/')
                    let filename = splitName[splitName.length - 1]
                    zip.file(filename, blob)
                }

                const zipfile = await zip.generateAsync({ type: 'blob' })
                downloadZip(zipfile)
            }

            props.setLoader(false)
        }
    }

    const hideModalResubmission = () => {
        setResubmissionModal(false)
    }

    /**
   * function to download zip file
   * @param file 
   */
    const downloadZip = (file: any) => {
        const a = document.createElement('a')
        a.download = 'docs.zip'
        const url = URL.createObjectURL(file)
        a.href = url

        //simulating click
        document.body.appendChild(a)
        a.click()
        a.remove()

        URL.revokeObjectURL(url)
    }

    /**
   * function to mark selected Images as Approved
   * @param images 
   */
    const markImageApprove = async (images: Array<any>) => {
        props.setLoader(true)

        let approveImage = []
        let allDocAlreadyApproved = true
        let updatedImages = JSON.parse(JSON.stringify(images))

        for (let image of updatedImages) {
            if (!image.is_approved) {
                let isDocIdExist = approveImage.includes(image.doc_id)
                if (!isDocIdExist) {
                    let activeImageDocId = image.doc_id
                    approveImage.push(activeImageDocId)
                }

                allDocAlreadyApproved = false
                image['is_approved'] = 1
            }
        }

        if (approveImage.length) {
            const markApprove: any = await markDocApproved({ document_id: approveImage, lead_id: leadId })
            if (markApprove && markApprove.status && markApprove.status === 200) {
                updateLeadDetailInRedux({ documents: updatedImages, type: 'update', updateStateData: { doc_status_id: markApprove?.data?.doc_status_id, document_status: markApprove?.data?.doc_status_value } });

                toast.success("Document Approved Successfully")
            }
        }

        if (allDocAlreadyApproved) {
            toast.error("Already Approved")
        }

        props.setLoader(false)


    }

    /**
 * function to update Redux state after various operations such as add, delete....
 * @param documents 
 * @param type 
 * @param subtype 
 */
    const updateLeadDetailInRedux = (params: any) => {
        let { documents, type, updateStateData, doc_status_id } = params;

        let leadDetail = insuranceState && insuranceState.leadDetail
        let details = { ...leadDetail }
        let images = (leadDetail && leadDetail.images) || []

        if (type === 'add' && documents.length) {
            let updatedImages = [...images, ...documents]

            details['images'] = updatedImages
            dispatch(updateLeadDetailsInStore(details))
        }

        if (type === 'delete') {
            let updatedImages = images && images.filter((e: any) => e.id !== documents.id)

            details['images'] = updatedImages
            //update document status
            if ((doc_status_id || doc_status_id === 0) && details.doc_status_id !== doc_status_id) {
                details['doc_status_id'] = doc_status_id
                details['document_status'] = DOC_STATUS[doc_status_id as keyof typeof DOC_STATUS]
            }
            
            dispatch(updateLeadDetailsInStore(details))
        }

        if (type === 'update') {
            let newImages = JSON.parse(JSON.stringify(images))

            let updatedImageArray: any = newImages && newImages.reduce((updatedImageArray: any[], ele: any) => {
                let exists = documents && documents.find((e: any) => e.id === ele.id)

                if (exists) updatedImageArray.push(exists)
                else updatedImageArray.push(ele)

                return updatedImageArray
            }, [])

            details['images'] = updatedImageArray;

            if (updateStateData) {
                details = { ...details, ...updateStateData };
            }
            dispatch(updateLeadDetailsInStore(details))

            setSelectedImages([])
        }

    }


    /**
     * function to mark selected images for Resubmission
     * @param documents 
     */
    const requestResubmission = async (documents: any) => {
        props.setLoader(true)

        let resubmitDocs = []
        let allDocAlreadyResubmitted = true
        let updatedDocs = JSON.parse(JSON.stringify(documents))

        for (let image of updatedDocs) {
            if (!image.is_approved && image.is_resubmit !== 2) {
                let activeImageId = image.id
                resubmitDocs.push(activeImageId)
                allDocAlreadyResubmitted = false
                image['is_resubmit'] = 2
            }
        }

        if (resubmitDocs.length) {
            let apiParams: any = {
                image_id: resubmitDocs,
                lead_id: leadId
            }

            if (resubmissionComment) apiParams['comment'] = resubmissionComment

            const requestResub: any = await requestDocResubmission(apiParams)
            if (requestResub && requestResub.status && requestResub.status === 200) {
                updateLeadDetailInRedux({ documents: updatedDocs, type: 'update', subType: 'resubmit', updateStateData: { doc_status_id: requestResub?.data?.doc_status_id, document_status: requestResub?.data?.doc_status_value } })
                toast.success("Document Resubmission Request Raised Successfully")
            }
        }

        if (allDocAlreadyResubmitted) {
            toast.error("Documents are already Marked")
        }

        props.setLoader(false)
    }

    const setResubmissionContent = (value: string) => {
        setResubmissionComment(value)
    }


    /**
 * function to upload new images
 * @param e 
 */
    const handleImageUpload = async (e: any) => {
        let selectedFiles = e.target.files
        props.setLoader(true)

        if (selectedFiles && selectedFiles.length) {
            let doc_path = []
            let errorFlag = false

            let insertParams = new FormData()
            insertParams.append('lead_id', `${leadId}`)
            insertParams.append('user_id', userId)

            let allowedExtensions = ['data:application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'data:application/pdf', 'data:application/msword', 'data:text/csv']
            let allowedExtensionsXls = ['data:application/vnd.ms-excel', 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'data:application/excel', 'data:application/x-excel', 'data:application/x-msexcel'];

            for (let file of selectedFiles) {
                const base64PreviewImg = await HELPER.toBase64(file)
                const filetype: any = await HELPER.detectMimeType(base64PreviewImg, { allowedExtensions: [...allowedExtensions, ...allowedExtensionsXls] })
                const fileSize = file.size

                if (!filetype) {
                    //check if csv/pdf/doc/docx
                    toast.error("File format should be jpg, jpeg, png, pdf, doc, docx, csv, xls, xlsx")
                    e.target.value = ''
                    errorFlag = true
                    break
                } else if (fileSize < DOC_UPLOAD_VALIDATION['minSize'] || fileSize > DOC_UPLOAD_VALIDATION['maxSize']) {
                    if(fileSize < DOC_UPLOAD_VALIDATION['minSize'] && allowedExtensionsXls.includes('data:'+file.type)){
                        // continue;
                    }else{
                        if(allowedExtensionsXls.includes('data:'+file.type)){
                            toast.error("Xls file size should be upto 5mb")
                        }else{
                            toast.error("file size should be between 25kb to 5mb")
                        }
                        e.target.value = ''
                        errorFlag = true
                        break
                    }
                    
                }

                if (!errorFlag) {
                    insertParams.append('files', file)

                    let docPath = { document_id: 0, document_type_id: docType, type: 'add' }
                    doc_path.push(docPath)
                }
            }

            if (!errorFlag) {
                insertParams.append('doc_path', JSON.stringify(doc_path))
                let uploadDocument: any = await addDocument(insertParams)

                //if upload successfull, then update inserted image in redux
                if (uploadDocument && uploadDocument?.status === 200) {
                    let newDocuments = uploadDocument?.data?.insertedDocuments ?? []
                    updateLeadDetailInRedux({ documents: newDocuments, type: 'add' })
                    toast.success(`${selectedFiles.length} ${selectedFiles.length > 1 ? 'Images' : 'Image'} uploaded Successfully`)
                }

                e.target.value = ''
            }
        }

        props.setLoader(false)

    }


    useEffect(() => {
        let leadDetail = insuranceState && insuranceState.leadDetail
        let leadId = leadDetail && leadDetail.leadId

        setLeadSubstatus(leadDetail?.sub_status_id)
        setLeadId(leadId)
        setLeadDetails(leadDetail)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceState.leadDetail])

    return (
        <div className={callFrom === 'UploadDocView' ? "upload-document-view-bx" : "file-submit-sec grid-view"}>
            {
                callFrom === 'DocGalleryView' && allImages && allImages.filter((e: any) => e.path).length
                    ?
                    <>
                        <div className="gird-view-select-opt">
                            <div className="custom-control custom-checkbox check-box-small select-check-bx">
                                <input id="Individual" type="checkbox" className="custom-control-input " name="Buyer Type" onChange={(e: any) => handleImageSelection(e.target.checked, 'Select-All')} checked={allImages && selectedImages.length && (allImages.filter((e: any) => e.is_resubmit !== 2)).length === selectedImages.length ? true : false} />
                                <label className="custom-control-label">Select All </label>
                            </div>
                            <div className="list-icn" onClick={() => toggleswitchgrid('grid')}>
                                <i className="ic-listview">
                                    <i className="path1"></i>
                                    <i className="path2"></i>
                                    <i className="path3"></i>
                                    <i className="path4"></i>
                                    <i className="path5"></i>
                                    <i className="path6"></i>

                                </i>
                            </div>
                        </div>
                    </>
                    :
                    callFrom !== 'DocGalleryView' && allImages && allImages.filter((e: any) => e.path).length
                        ?
                        <div className="select-all-opt">
                            <div className="custom-control custom-checkbox check-box-small select-check-bx">
                                <input id="Individual" type="checkbox" className="custom-control-input " name="Buyer Type" onChange={(e: any) => handleImageSelection(e.target.checked, 'Select-All')} checked={allImages && selectedImages.length && (allImages.filter((e: any) => e.is_resubmit !== 2 && e.doc_id !== 0)).length === selectedImages.length ? true : false} />
                                <label className="custom-control-label">Select All </label>
                            </div>
                        </div>
                        : null
            }
            <div className={callFrom === "DocGalleryView" ? "grid-item-list" : "p-xl-b"}>
                <div className="row scroll">
                    {
                        allImages && allImages.length
                            ?
                            allImages.map((ele: any, indx: number) => {
                                if (ele.path) {
                                    let splitName = ele.path.split('?')
                                    let splitPathName = splitName?.[0]?.split('.')
                                    let fileType = (splitPathName[splitPathName.length - 1])?.toLowerCase()
                                    fileType = ['csv', 'xls', 'xlsx'].includes(fileType)  ? 'excel' : (fileType === 'doc' || fileType === 'docx') ? fileType = 'word' : fileType

                                    let isLoanDoc = ele.parent_id === DOCUMENT_CATEGORY['firstSubmit']['childs']['govt_id']['value'] || ele.parent_id === DOCUMENT_CATEGORY['firstSubmit']['childs']['credit_advice_doc']['value']
                                    
                                    
                                    return (
                                        <div className={callFrom === 'UploadDocView' ? "col-md-3" : "col-md-4"} key={indx}>
                                            <div className="img-bx-select-opt">
                                                <div className="image-bx">
                                                    {fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ? <img src={ele.path} className='active' style={{ maxHeight: '135px' }} alt={`document_image_${indx}`} /> : <a href={ele.path} target="_blank" rel="noreferrer"><i className={`ic-${fileType}-file icons-normal`}></i></a>}

                                                    {
                                                        callFrom === 'UploadDocView'
                                                            ?
                                                            <span className="imgTag" data-key="tag_img" data-id={`id_${indx}`} data-document_id={`doc_${indx}`} onClick={() => {
                                                                setSelectedImages([])
                                                                handleImageClick(ele)
                                                            }}>Tag</span>
                                                            : null
                                                    }
                                                    {
                                                        ele.is_approved === 1
                                                            ?
                                                            <span className="image-status">Approved</span>
                                                            : ""
                                                    }
                                                    {
                                                        ele.is_resubmit === 2 && !isLoanDoc
                                                            ?
                                                            <span className="image-requested">Requested</span>
                                                            : ""
                                                    }
                                                </div>
                                                {
                                                    callFrom === 'UploadDocView' ?
                                                    (leadDetails?.application_type_id === APP_TYPE_AGENCY && leadDetails?.agency_id && [AGENCY_STATUS['inprogress'], AGENCY_STATUS['rejected']].includes(leadDetails?.agency_status))
                                                    ||
                                                        (docType === 2 && (ele.is_approved === 1 || !leadSubstatus || leadSubstatus >= 7 || !editAccess)) ||
                                                            (docType === 3 && (!leadSubstatus || (leadSubstatus >= 12 && leadSubstatus !== STATUS_SUB_STATUS['subStatus']['payment_from_customer_to_partner_broker']))) ||
                                                            ((docType === 1 && (ele.is_approved === 1 || ele.is_resubmit === 2 || !editAccess || leadSubstatus >= 4 || leadSubstatus === 3)) || isLoanDoc || (leadDetails?.lead_type === LEAD_TYPE_CRM && ele?.source === 1))
                                                            ?
                                                            null
                                                            :
                                                            <div className="close-icn" onClick={() => handleImageDelete(ele)}>
                                                                <i className="ic-clearclose"></i>
                                                            </div>
                                                        : null
                                                }
                                                <div className="custom-control custom-checkbox check-box-small">

                                                    {ele.doc_id ?
                                                        <>
                                                            {
                                                                ele.is_resubmit === 2 ?
                                                                    <input disabled={true} checked={selectedImages.find((e: any) => e.id === ele.id) ? true : false} id="Individual" type="checkbox" className="custom-control-input " name="Buyer Type" />
                                                                    :
                                                                    <input checked={selectedImages.find((e: any) => e.id === ele.id) ? true : false} id="Individual" type="checkbox" className="custom-control-input " name="Buyer Type" onChange={(e: any) => handleImageSelection(e.target.checked, ele)} />

                                                            }
                                                            <label className="custom-control-label">
                                                                {`${ele.parent_name ? ele.parent_name + "(" + ele.doc_name + ")" : ele.doc_name}`}
                                                            </label>
                                                                                                                       
                                                        </>
                                                        : null

                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return ""
                                }
                            })
                            :
                            ''
                    }

                    {
                        //for first submit, if lead source is app, then show add more only if images are present 
                        callFrom === 'UploadDocView' &&
                            editAccess &&
                            leadDetails?.status_id !== STATUS_SUB_STATUS['status']['lost'] &&
                            ((leadDetails?.application_type_id === APP_TYPE_AGENCY && ![AGENCY_STATUS['inprogress'], AGENCY_STATUS['rejected']].includes(leadDetails?.agency_status)) || leadDetails?.application_type_id !== APP_TYPE_AGENCY) &&
                            ((docType === 1 && leadDetails?.source === 1 && ((allImages && allImages.filter((e: any) => e.path && e.parent_id !== DOCUMENT_CATEGORY['firstSubmit']['childs']['govt_id']['value'] && e.parent_id !== DOCUMENT_CATEGORY['firstSubmit']['childs']['credit_advice_doc']['value']).length) || insuranceState?.leadDetail?.lead_on_behalf_user_id) && leadSubstatus < STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker'] && leadSubstatus !== STATUS_SUB_STATUS['subStatus']['approval_in_progress'])
                                || (docType === 1 && leadDetails?.source === 2 && leadSubstatus < STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker'])
                                || (docType === 2 && leadSubstatus >= STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker'] && leadSubstatus <= STATUS_SUB_STATUS['subStatus']['policy_generated'])
                                || (docType === 3 && leadSubstatus > STATUS_SUB_STATUS['subStatus']['policy_generated'] && (leadSubstatus < STATUS_SUB_STATUS['subStatus']['payment_to_sales_team'] || leadSubstatus === STATUS_SUB_STATUS['subStatus']['payment_from_customer_to_partner_broker']))
                            ) && (insuranceState?.leadDetail?.lead_type !== LEAD_TYPE_CRM || (insuranceState?.leadDetail?.lead_type === LEAD_TYPE_CRM && docType !== 1))

                            ?
                            <div className="col-md-3" key={"add-more"}>
                                <div className="img-bx-select-opt">
                                    <div className="image-bx more-file-upload">
                                        <img src={"/images/upload_icn.svg"} className="upload-icn" alt='upload-icon' />
                                        <input type="file" name="upload" accept="image/*, application/doc, application/pdf, application/msword, application/ms-doc, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" multiple onChange={(e) => handleImageUpload(e)} />
                                        <p>Add More</p>
                                    </div>

                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>

            {/** if selected image has length */
                selectedImages && selectedImages.length
                    ?
                    <>
                        <div className="btn-line-img">
                            <button className="btn-line" onClick={() => handleBtnClick('download')}>{selectedImages.length > 1 ? "Download All" : "Download"}</button>
                            {/* <button className="btn-line" onClick={() => setResubmissionModal(true)}>Request Re-submission</button> */}
                            {
                                editAccess && ((leadDetails?.application_type_id === APP_TYPE_AGENCY && leadDetails?.agency_id && ![AGENCY_STATUS['inprogress'], AGENCY_STATUS['rejected']].includes(leadDetails?.agency_status)) || leadDetails?.application_type_id !== APP_TYPE_AGENCY) && ((docType === DOCUMENT_CATEGORY['firstSubmit']['doc_type_id'] && leadSubstatus < STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker'] && leadSubstatus !== STATUS_SUB_STATUS['subStatus']['approval_in_progress'])
                                    || (docType === DOCUMENT_CATEGORY['disbursal']['doc_type_id'] && !insuranceState.leadDetail.policy_shared_customer_source))
                                    ?
                                    <button className="btn-line" onClick={() => handleBtnClick('approve')}>{selectedImages.length > 1 ? "Mark All Approved" : "Mark as Approved"}</button>
                                    : null
                            }
                        </div>
                    </>
                    : ''
            }

            {
                resubmissionModal
                    ?

                    <ResubmissionModal show={resubmissionModal} handleClose={hideModalResubmission} handleBtnClick={handleBtnClick} setResubmissionContent={setResubmissionContent} />
                    : null
            }
        </div>
    )
}