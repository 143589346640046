let Constant = require(`./${process.env.REACT_APP_COUNTRY_CODE}/constant`) 

export const ADD_ONS_TYPE = Constant.ADD_ONS_TYPE
export const AGENCY_DOC_STATUS = Constant.AGENCY_DOC_STATUS
export const AGENCY_PAYMENT_OPTION = Constant.AGENCY_PAYMENT_OPTION
export const AGENCY_PREFILLED_KEYS = Constant.AGENCY_PREFILLED_KEYS
export const AGENCY_SOURCE = Constant.AGENCY_SOURCE
export const AGENCY_STATUS = Constant.AGENCY_STATUS
export const AGENCY_VAL = Constant.AGENCY_VAL
export const AGENT = Constant.AGENT
export const AGENT_ROLE_ID = Constant.AGENT_ROLE_ID
export const ALLOW_LEAD_SOURCE = Constant.ALLOW_LEAD_SOURCE
export const ALPHABETIC_PATTERN = Constant.ALPHABETIC_PATTERN
export const APIV = Constant.APIV
export const API_TYPES = Constant.API_TYPES
export const API_URL = Constant.API_URL
export const APP_AGENCY = Constant.APP_AGENCY
export const APP_TYPE_AGENCY = Constant.APP_TYPE_AGENCY
export const APP_TYPE_RENEWAL = Constant.APP_TYPE_RENEWAL
export const APP_TYPE_STANDALONE = Constant.APP_TYPE_STANDALONE
export const APP_TYPE_WITH_UCF = Constant.APP_TYPE_WITH_UCF
export const ARO_ROLE_ID = Constant.ARO_ROLE_ID
export const CLIENT_REFERRAL = Constant.CLIENT_REFERRAL
export const CURRENCY_SYMBOL = Constant.CURRENCY_SYMBOL
export const DEALER_PRESENT_NO = Constant.DEALER_PRESENT_NO
export const DEALER_PRESENT_YES = Constant.DEALER_PRESENT_YES
export const DOCUMENTS_CATEGORY_INBOUND = Constant.DOCUMENTS_CATEGORY_INBOUND
export const DOCUMENT_CATEGORY = Constant.DOCUMENT_CATEGORY
export const DOCUMENT_CATEGORY_OTHER_DOCUMENT = Constant.DOCUMENT_CATEGORY_OTHER_DOCUMENT
export const DOC_STATUS = Constant.DOC_STATUS
export const DOC_UPLOAD_VALIDATION = Constant.DOC_UPLOAD_VALIDATION
export const DSA = Constant.DSA
export const DSA_LEAD_SOURCE = Constant.DSA_LEAD_SOURCE
export const EFFECTIVITY_EXPIRY_INTERVAL = Constant.EFFECTIVITY_EXPIRY_INTERVAL
export const ENCRYPTED_AGENCY_FIELDS = Constant.ENCRYPTED_AGENCY_FIELDS
export const ENCRYPTED_FIELDS = Constant.ENCRYPTED_FIELDS
export const EXPECTED_INSTALLMENT = Constant.EXPECTED_INSTALLMENT
export const FIELD_TYPE = Constant.FIELD_TYPE
export const FIVE_YEAR = Constant.FIVE_YEAR
export const INBOUND_PAYMENT_FROM_BROKER = Constant.INBOUND_PAYMENT_FROM_BROKER
export const INBOUND_PAYMENT_FROM_CUSTOMER = Constant.INBOUND_PAYMENT_FROM_CUSTOMER
export const INBOUND_PAYMENT_FROM_INSURANCE_PARTNER = Constant.INBOUND_PAYMENT_FROM_INSURANCE_PARTNER
export const INBOUND_PAYMENT_FROM_OTO_INSURANCE = Constant.INBOUND_PAYMENT_FROM_OTO_INSURANCE
export const INBOUND_PAYMENT_FROM_SALES_TEAM = Constant.INBOUND_PAYMENT_FROM_SALES_TEAM
export const INSTALLMENT = Constant.INSTALLMENT
export const INSURANCE_LIST_SORT = Constant.INSURANCE_LIST_SORT
export const INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER = Constant.INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER
export const INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE = Constant.INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE
export const INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO = Constant.INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO
export const INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER = Constant.INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER
export const INS_TYPE_COMPREHENSIVE = Constant.INS_TYPE_COMPREHENSIVE
export const INS_TYPE_CTPL = Constant.INS_TYPE_CTPL
export const LEAD_TYPE_CRM = Constant.LEAD_TYPE_CRM
export const LEAD_TYPE_DEALER_APP = Constant.LEAD_TYPE_DEALER_APP
export const LEAD_TYPE_SFA_APP = Constant.LEAD_TYPE_SFA_APP
export const LEAD_TYPE_WEB_APP = Constant.LEAD_TYPE_WEB_APP
export const LOST_REASON_FLAGS = Constant.LOST_REASON_FLAGS
export const LOST_REASON_IDS = Constant.LOST_REASON_IDS
export const MARKETING = Constant.MARKETING
export const MARKETING_LEAD_SOURCE = Constant.MARKETING_LEAD_SOURCE
export const MAX_DISCOUNT_PERCENT = Constant.MAX_DISCOUNT_PERCENT
export const MAX_FILE_UPLOAD = Constant.MAX_FILE_UPLOAD
export const MAX_FILE_UPLOAD_POLICY_GENRATED = Constant.MAX_FILE_UPLOAD_POLICY_GENRATED
export const MIN_DISCOUNT = Constant.MIN_DISCOUNT
export const OUTBOUND_PAYMENT_TO_BROKER = Constant.OUTBOUND_PAYMENT_TO_BROKER
export const OUTBOUND_PAYMENT_TO_SALES_TEAM = Constant.OUTBOUND_PAYMENT_TO_SALES_TEAM
export const PAYMENT_TERM_INSTALLMENT = Constant.PAYMENT_TERM_INSTALLMENT
export const PAYMENT_TYPE_FROM_CUSTOMER_TO_BROKER = Constant.PAYMENT_TYPE_FROM_CUSTOMER_TO_BROKER
export const PAYMENT_TYPE_INBOUND = Constant.PAYMENT_TYPE_INBOUND
export const PAYMENT_TYPE_OUTBOUND = Constant.PAYMENT_TYPE_OUTBOUND
export const PHILIPPINE_CURRENCY = Constant.PHILIPPINE_CURRENCY
export const POLICY_GENERATED_TYPE = Constant.POLICY_GENERATED_TYPE
export const REDIRECT_URL = Constant.REDIRECT_URL
export const REDIRECT_URLS = Constant.REDIRECT_URLS
export const REFERRAL_FEE_PAYOUT = Constant.REFERRAL_FEE_PAYOUT
export const REFERRAL_FEE_PAYOUT_OUTBOUND = Constant.REFERRAL_FEE_PAYOUT_OUTBOUND
export const REFERRAL_FEE_PAYOUT_WHT_EXCLUSIVE_RATE = Constant.REFERRAL_FEE_PAYOUT_WHT_EXCLUSIVE_RATE
export const REGEX_DECIMAL_VALIDATION = Constant.REGEX_DECIMAL_VALIDATION
export const REGEX_DECIMAL_VALIDATION_8 = Constant.REGEX_DECIMAL_VALIDATION_8
export const REGEX_EMAIL_VALIDATION = Constant.REGEX_EMAIL_VALIDATION
export const ROLE = Constant.ROLE
export const ROLE_FEATURES = Constant.ROLE_FEATURES
export const SEVEN_DAYS = Constant.SEVEN_DAYS
export const SHARE_METHOD = Constant.SHARE_METHOD
export const SIX = Constant.SIX
export const SIXTY_DAYS = Constant.SIXTY_DAYS
export const SOFT_APPROVAL_RECEIVED_TYPE = Constant.SOFT_APPROVAL_RECEIVED_TYPE
export const SOURCE_WEB = Constant.SOURCE_WEB
export const STANDALONE_PAYMENT_OPTION = Constant.STANDALONE_PAYMENT_OPTION
export const STATUS_SUB_STATUS = Constant.STATUS_SUB_STATUS
export const TEMPLATE_TYPE_BROKER = Constant.TEMPLATE_TYPE_BROKER
export const TEMPLATE_TYPE_POLICY_SHARED = Constant.TEMPLATE_TYPE_POLICY_SHARED
export const TWO_MONTH = Constant.TWO_MONTH
export const WHATSAPP_WEB_URL = Constant.WHATSAPP_WEB_URL
export const WITHOUT_RULE_ENGINE = Constant.WITHOUT_RULE_ENGINE
export const WITH_RULE_ENGINE = Constant.WITH_RULE_ENGINE
export const WITH_RULE_ENGINE_ID = Constant.WITH_RULE_ENGINE_ID
export const WITH_UCF_PAYMENT_OPTION = Constant.WITH_UCF_PAYMENT_OPTION
export const apikey = Constant.apikey
