/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { NavLink, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import InsuranceForm from './InsuranceForm';
import InsuranceService from 'services/InsuranceService';
import FullPageLoader from 'common/FullPageLoader';
import OtherDetailsFormComponent from './OtherDetails';
import PersonalDetailsFormComponent from './PersonalDetails';
import QuotesList from './QuotesList'
import DocumentForm from './DocumentForm'
import { getStateWiseCityList,getMasterConfig, fetchBaseRate } from '@src/store/slices/common';
import { useSelector, useDispatch } from 'react-redux';
import { callServiceToCreateLead, getAddonList,isShowDynamicField, updateInsuranceForm } from './FunctionList'
import { toast } from 'react-toastify';
import secureStorage from '@src/config/encrypt';
import { APP_TYPE_RENEWAL, APP_TYPE_WITH_UCF, FIELD_TYPE, PAYMENT_TERM_INSTALLMENT, REGEX_EMAIL_VALIDATION } from '@src/config/constant';
import { APP_TYPE_STANDALONE, INS_TYPE_CTPL, LEAD_TYPE_WEB_APP,INS_TYPE_COMPREHENSIVE, ENCRYPTED_FIELDS } from '@src/config/constant';
import ReactSelect from '@common/ReactSelect';
import { clearLoanData, fetchLoanApplicationById, fetchLoanApplications, fetchRenewalApplicationById, fetchRenewalApplications, getFormConfig, saveFormDetails, updateLeadDetailsInStore, updateRenewalState, saveInsurance } from '@src/store/slices/insurance';
import React from "react";
import { times } from 'lodash';
import { EncodetoBase64, removeCurrencyFormatting } from '@src/config/helper';
import { Nav, Tab } from 'react-bootstrap';


const CreateInsurancePolicy = (props: any) => {
    const navigate = useNavigate();
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [searchParams, setSearchParams] = useSearchParams();
    const [redirect, setRedirect] = useState(false);
    /* eslint-enable @typescript-eslint/no-unused-vars */
    const [sectionList, setSectionList] = useState<any>([])
    const [applicationType, setApplicationType] = useState(1)
    const [masterData, setMasterData] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(1)
    const [page, setPage] = useState(1);
    const [versionSeat, setVersionSeat] = useState(0)
    const [extraField, setExtraField] = useState<any>({})
    
    const [insuranceType, setInsuranceType] = useState<any>(1);
  
    const leadDetails: any = {}
    const dispatch: any = useDispatch();
    const commonState = useSelector((state: any) => state.common);
    const insuranceState = useSelector((state: any) => state.insurance);
    const [userID, setUserID] = useState('')
    let [leadhashId, setLeadhashId] = useState();

    let [finalData, setFinalData] = useState('');
    let [trackSubmitStatus, setTrackSubmitStatus] = useState(false);

    let subRoute:any = searchParams.get('step');
    let routeLeadId:any = searchParams.get('lead_id');

    // const apptype = APP_TYPE_STANDALONE; //DEFAULT STANDALONE
    const location = useLocation()

    const [apptype, setApptype] = useState(APP_TYPE_STANDALONE)
    const [loanApplicationList, setLoanApplicationList] = useState<Array<any>>([])
    const [activeLoanID, setActiveLoanID] = useState<number | null>(null)
    const [errors, setErrors] = useState<any>({ loan_id: null })
    const [renewalAppList, setRenewalAppList] = useState<Array<any>>([])
    const [activeRenewalID, setActiveRenewalID] = useState<number | null>(null)
    const [activeTab, setActiveTab] = useState<string>('first')

    useEffect(() => {
        let result = secureStorage.getItem("authUserInfo");
        if (!result) {
            setRedirect(true)
        } else {
            let userInfo = result.user_data; setUserID(userInfo.user_id)
        }
        //clear redux for renewal
        return (() => {
            dispatch(updateLeadDetailsInStore({}))
        })
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if ((commonState.stateWithCity).length === 0) {
            dispatch(getStateWiseCityList())
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchFormConfig()
        // eslint-disable-next-line
    }, [applicationType, masterData])

    useEffect(() => {
        fetchMasterConfig()
        if (page === 1) {
            navigate('/create-insurance-policy?step=insurance_form');
        }

        //when navigated back from agency, set selected apptype & rule engine
        let { apptype, ruleEngine, eventKey } = location?.state || {}
        if (apptype) {
            setApplicationType(ruleEngine)
            onApptypeChange({ apptype, eventKey })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (page > 2 && sectionList[0]?.blocks[0]?.fields?.length) {
            validateBaseIssueRate().then((res: any) => {
                if (!res) {
                    setPage(1)
                    navigate('/create-insurance-policy?step=insurance_form')
                }
            })
        }
    // eslint-disable-next-line
    }, [location.search])

    useEffect(() => {
        if (apptype === APP_TYPE_RENEWAL) {
            //for pre-filling vehicle details
            updateInsuranceForm({ ...insuranceState?.leadDetail, type: 'renewal' }, sectionList[3]?.blocks?.[0]?.fields)
            //for other details
            updateInsuranceForm(insuranceState?.leadDetail, sectionList[3]?.blocks?.[1]?.fields)
        }
        // eslint-disable-next-line
    }, [sectionList])

    const onChangeFieldItem = (fieldItem: any) => {
        if (fieldItem?.name === 'phone' && fieldItem?.value?.length >= 10 && apptype !== APP_TYPE_RENEWAL) fetchCustomerDataWithMobileNumber(fieldItem?.value)
    }
    const fetchCustomerDataWithMobileNumber = async (mobile: any) => {
        setLoading(true)
        let params = { phone: mobile }
        let result = await InsuranceService.getCustomerDetails(params)
        
        if (result?.data?.data && sectionList?.length >= 3) {
            let customerDetails = result.data.data;
            let response : any;
            if(customerDetails?.['per_city']){
                response = await InsuranceService.getlocalityByCity({ city: [customerDetails?.['per_city'], customerDetails?.['cur_city']] });
            }
            setLoading(false)
            let localityList = response?.data?.data || [];
            let sectionData = sectionList[2]
            let blockList = sectionData['blocks'] as []
            blockList?.forEach((bloackItem: any) => {
                let fieldList = bloackItem['fields'] as []
                fieldList?.map(async(fieldItem: any) => {
                    switch (fieldItem.type) {
                        case FIELD_TYPE.CURRENCY:
                        case FIELD_TYPE.TELEPHONE:
                        case FIELD_TYPE.SUM:
                        case FIELD_TYPE.TEXT_BOX:
                        case FIELD_TYPE.MOBILE_FIELD:
                        case FIELD_TYPE.FUTURE_DATE:
                        case FIELD_TYPE.DATE_PICKER:
                             //inputField
                            if (customerDetails[fieldItem.name]) {
                                fieldItem.value = customerDetails[fieldItem.name]
                            }
                            break
                        case FIELD_TYPE.CHECK_BOX:
                            //checkbox
                            if (customerDetails[fieldItem.name]) {
                                fieldItem.value = customerDetails[fieldItem.name] === 1 ? false : true
                            }
                            break
                        case FIELD_TYPE.LOCALITY:
                            if(customerDetails[fieldItem.name]){
                                fieldItem.value = customerDetails[fieldItem.name];
                                fieldItem.options = localityList?.map((item:any) => { return {label : item.label, value : item.value}}) || []
                            }
                            break
                        default: {
                            //dropdown
                            if (customerDetails[fieldItem.name]) {
                                fieldItem.value = customerDetails[fieldItem.name]
                            }
                        }
                    }

                })
            })
            // for handle validation
            if(trackSubmitStatus){
                isValidAllField(sectionData);
            }      
        } else {
            setLoading(false)
        }
    }
    
    const fetchMasterConfig = async () => {
        setLoading(true);
        if (!commonState?.common || !Object.keys(commonState?.common)?.length) {
            dispatch(getMasterConfig()).then((resp: any) => {
                if (resp.status === 200) {
                    setMasterData(resp.data);
                    fetchFormConfig();
                }
            });
        }
        else {
            setMasterData(commonState.common);
            fetchFormConfig();
        }
        
    }

    const fetchFormConfig = async () => {
        if (!masterData) return
        let result = null
        if (!insuranceState?.formConfig?.length) {
            result = await dispatch(getFormConfig(applicationType)) // adding response to redux --- await InsuranceService.getFormConfig(applicationType)
            result = result.insuranceFormFields
        } else result = insuranceState.formConfig

        if (result?.length) {
            let tempList:any = JSON.parse(JSON.stringify(result))
            tempList.forEach((sectionItem: any) => {
                sectionItem?.blocks?.forEach((blockItem: any) => {
                    blockItem?.fields?.forEach((fieldItem: any) => {
                        if (fieldItem.name === 'make_year' || fieldItem.name === 'payment_tenure' || fieldItem.name === 'payment_term') {
                            fieldItem['options'] =[]
                        }else if (fieldItem.name in masterData) {
                            fieldItem['options'] = masterData[fieldItem.name]
                        }
                        if (fieldItem.name === 'make' && leadDetails[fieldItem.name]) {
                            fieldItem.value = { value: leadDetails[fieldItem.name], label: leadDetails['make_name'] }
                        } else if (fieldItem.name === 'model' && leadDetails[fieldItem.name]) {
                            fieldItem.value = { value: leadDetails[fieldItem.name], label: leadDetails['model_name'] }
                        } 
                        else if (leadDetails[fieldItem.name]) {
                            fieldItem.value = leadDetails[fieldItem.name]
                        }
                    })
                })
            })
            setSectionList([...tempList]);
        }
        setLoading(false);
       
    }

    const onApplicationTypeValueChanged = (value: any) => {
        dispatch(updateRenewalState({ insuranceForm: false, customerDetails: false }))
        setApplicationType(value);
        // setActiveLoanID(null)
    }

    const getLoanApplications = () => {
        fetchFormConfig();
        setLoading(true)
        fetchLoanApplications({}).then((res: any) => {
            if(res && res.status === 200 && res.data) {
                setLoanApplicationList(res.data)
            }
        }).finally(() => setLoading(false))
    }

    const getRenewalApplications = () => {
        fetchFormConfig()
        setLoading(true)
        fetchRenewalApplications({}).then((res: any) => {
            if(res && res.status === 200 && res.data) {
                setRenewalAppList(res.data)
            }
        }).finally(() => setLoading(false))

    }

    const getApplicationById = (params: any) => {
        let { type, id } = params
        setLoading(true)

        switch (type) {
            case 'loan':
                dispatch(fetchLoanApplicationById({ loan_id: id })).then((res: any) => {
                    if (res && res.data) {
                        //for pre-filling vehicle details
                        updateInsuranceForm(res.data, sectionList[3]?.blocks?.[0]?.fields)
                    }
                }).finally(() => setLoading(false))
                break;
            case 'renewal':
                dispatch(fetchRenewalApplicationById({ "lead_id": id })).then((res: any) => {
                    if (res && res.data) {
                        let { rule_engine_id } = res.data
                        setPage(1)
                        //for pre-filling vehicle details
                        updateInsuranceForm({ ...res.data, type: 'renewal' }, sectionList[3]?.blocks?.[0]?.fields)
                        //for other details
                        updateInsuranceForm(res.data, sectionList[3]?.blocks?.[1]?.fields)

                        if(rule_engine_id !== applicationType) {
                            onApplicationTypeValueChanged(rule_engine_id)
                        }
                    }
                }).finally(() => setLoading(false))

                break;
            default:
                break;
        }
    }


    const handleLeadIDSelection = (type: string, value: any, field: any) => {
        let fieldErrors = {...errors}
        
        switch(type) {
            case 'loan':
                dispatch(clearLoanData({}))
                setActiveLoanID(value.value)
               
                fieldErrors = {...fieldErrors, loan_id: null}
                let loanId = value.value
                getApplicationById({ type: 'loan', id: loanId })
                break;
            
            case 'renewal':
                dispatch(updateRenewalState({ insuranceForm: false, customerDetails: false })) // set state back to false
                dispatch(updateLeadDetailsInStore({}))
                setActiveRenewalID(value.value)
                fieldErrors = { ...fieldErrors, renewal_id: null }
                let renewalLeadId = value.value
                getApplicationById({ type: 'renewal', id: renewalLeadId })
                break;
            
            default:
                break;
        }

        setErrors({...fieldErrors})
        //reset addons
        let addons = sectionList?.[0]?.blocks?.[0].fields?.find((e: any) => e.name === 'addon_detail')
        if (addons) {
            addons['selected_addon'] = []
            addons['original_list'] = []
        }
    }

    const isValidAllField = (sectionItem: any) => {
        let flag = true
        let blockList = sectionItem['blocks'] as [] 
        blockList.forEach((blockItem: any) => {
            blockItem.fields.forEach((fieldItem: any) => {
                fieldItem['error_message'] = ''

                //WITH UCF BYPASS ERROR
                if(fieldItem.name === 'paid_by_customer' && [APP_TYPE_WITH_UCF].includes(apptype)){
                    return
                }

                if (fieldItem.name === 'expected_installment' && [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(apptype)) {
                    let sectionItem = sectionList[2]
                    let paymentTerm = getFieldItem(sectionItem['blocks'], 'payment_term')
                    let isInstallment = paymentTerm?.value?.value === PAYMENT_TERM_INSTALLMENT ? true : false
                    let tenure = getFieldItem(sectionItem['blocks'], 'payment_tenure')
                    let totalInstallments = tenure?.value?.label?.split(' ')[0] || null

                    if (+totalInstallments && isInstallment) {
                        let value = fieldItem.value
                        let totalPremium = insuranceState?.createInsurance?.total_premium || null
                        let installmentSum = 0

                        times((totalInstallments), (idx: number) => {
                            let editFlag = false
                            let errorObj: any = {}
                            installmentSum += +(value?.[idx + 1]?.expected_installment_amount ? (removeCurrencyFormatting(value?.[idx + 1]?.expected_installment_amount) || 0) : 0)

                            if (!value?.[idx + 1]?.expected_installment_amount) {
                                errorObj['expected_installment_amount'] = `Please enter ${idx + 1} Expected Amount`
                                editFlag = true
                            }

                            if (+value?.[idx + 1]?.expected_installment_amount === 0) {
                                errorObj['expected_installment_amount'] = `Amount Cannot be 0`
                                editFlag = true
                            }

                            if(!value?.[idx + 1]?.expected_installment_date) {
                                editFlag = true
                                errorObj['expected_installment_date'] = `Please select ${idx + 1} Expected Date`
                            }

                            if (editFlag) {
                                fieldItem['error_message'] = { ...fieldItem?.['error_message'], [idx + 1]: { ...errorObj } }
                                flag = false
                                setValue(value + 1)
                            }
                        })

                        if(!totalPremium || (totalPremium !== +installmentSum.toFixed(2))) {
                            flag = false
                            setValue(value + 1)
                            toast.error('Sum of Expected Installment should be equal to total Premium')
                        }
                    }
                }

                if (fieldItem.name === 'name' && fieldItem.value) {
                    let isValidName = fieldItem.value.trim();
                    if (!isValidName) {
                        fieldItem['error_message'] = 'Please enter valid name'
                        flag = false
                        setValue(value + 1)
                    }
                }
                // when Lead source is 3 (client referal/ Agent) on that case validation msg show
                if(blockItem?.fields[0]?.value?.value ===3 || blockItem?.fields[0]?.value?.value ===4){
                    if (fieldItem.name === 'referrer_name' && fieldItem.value) {
                        let isValidReferrerName = fieldItem.value.trim();
                        if (!isValidReferrerName) {
                            fieldItem['error_message'] = 'Please enter Referrer Name'
                            flag = false
                            setValue(value + 1)
                        }
                    }
                    if (fieldItem.name === 'referrer_mobile' && fieldItem.value) {
                        let isValidReferrerMobile = (/^[9]\d{9}$/).test(fieldItem.value)
                        if (!isValidReferrerMobile) {
                            fieldItem['error_message'] = 'Mobile number should start with 9 and 10 digit number'
                            flag = false
                            setValue(value + 1)
                        }
                    }
                }
                // when Lead source is 5 (DSA) on that case validation msg show
                if(blockItem?.fields[0]?.value?.value ===5){
                    if (fieldItem.name === 'referrer_name' && fieldItem.value) {
                        let isValidReferrerName = fieldItem.value.trim();
                        if (!isValidReferrerName) {
                            fieldItem['error_message'] = 'Please enter agency Name'
                            flag = false
                            setValue(value + 1)
                        }
                    }
                }
                if (fieldItem.name === 'phone' && fieldItem.value) {
                    let isValidMobile = (/^[9]\d{9}$/).test(fieldItem.value)
                    if (!isValidMobile) {
                        fieldItem['error_message'] = 'Mobile number should start with 9 and 10 digit number'
                        flag = false
                        setValue(value + 1)
                    }
                }
                if (fieldItem.name === 'alt_phone' && fieldItem.value) {
                    let isValidMobile = (/^[9]\d{9}$/).test(fieldItem.value)
                    if (!isValidMobile) {
                        fieldItem['error_message'] = "Alternate Mobile should start with 9 and 10 digit number"
                        flag = false
                        setValue(value + 1)
                    }
                }
                if (fieldItem.name === 'email' && fieldItem.value) {
                    // eslint-disable-next-line
                    const validEmailRegex = RegExp(REGEX_EMAIL_VALIDATION); 
                    if (!validEmailRegex.test(fieldItem.value)) {
                        fieldItem['error_message'] = 'Please enter valid email'
                        flag = false
                        setValue(value + 1)
                    } 
                }
                if (fieldItem.type === 'select_addon' && fieldItem.is_required) {
                    //SKIP IF NO ADDON SELECTED
                    // let addonList:any = getAddonList(fieldItem,"",applicationType)
                    // if (!addonList?.length) {
                    //     fieldItem['error_message'] = fieldItem.error_msg
                    //     flag = false
                    //     setValue(value + 1)
                    // }
                }else if (isShowDynamicField(fieldItem, blockList) && fieldItem.is_required && !fieldItem?.value && (fieldItem.name !== "copy_of_insurance" || (fieldItem.name === 'copy_of_insurance' && apptype === APP_TYPE_WITH_UCF))) {
                    // when lead is UCF then no required error show
                     if(fieldItem.name === 'email'  && +apptype === 1){
                        fieldItem['error_message'] = ''
                    }else{
                        fieldItem['error_message'] = fieldItem.error_msg
                        flag = false
                        setValue(value + 1)
                    }                          
                }
                
                if(blockItem.key === "insurance_data" && fieldItem.name === "acc_price" && fieldItem.value && Number(fieldItem.value) > 0){
                    let fmvValue = "";
                    fmvValue = blockItem.fields.find((data : any) => data?.name === "car_fmv")?.value;
                    if(fmvValue && Number(fieldItem.value) > Number(fmvValue)){
                        fieldItem['error_message'] = "Accessories price should be less than FMV of Car";
                        flag = false
                        setValue(value + 1)
                    }
                }

                if (fieldItem.name === 'plate_number' && fieldItem.value) {
                    let alphanumericPattern = /^[a-z0-9]+$/i;
                    if (!alphanumericPattern.test(fieldItem.value)) {
                        fieldItem['error_message'] = 'Plate number should be alphanumeric'
                        flag = false
                        setValue(value + 1)
                    } 
                }

            })
        })

        if (apptype === APP_TYPE_WITH_UCF && !activeLoanID) {
            flag = false
            setErrors({ loan_id: true })
        }

        return flag
    }
    const isValidAddon = async ()=>{
        let flag =true; 
        let message:any=[];
        let sectionFields:any=sectionList[0]['blocks'][0]["fields"];
        if(sectionFields.length>0){
            let fieldItem= sectionFields.filter((el:any)=>el.type==="select_addon" && el.is_required);
            let addonList:any = getAddonList(fieldItem[0],"",applicationType);
            if (!addonList?.length) {
                //SKIP IF NO ADDON SELECTED
                // message.push("Please select addons");
                // flag = false
            }else{
                for(let data of addonList){
                    if(data.id===1 ){
                        if(!data["coverage_amount"]){
                            message.push("Please select coverge amount for Bodily Injured");
                            flag = false
                        }
                        if(!data["premium_amount"]){
                            message.push("Please Enter Premium amount for Bodily Injured");
                            flag = false
                        }
                    }else if(data.id===2){
                        if(!data["coverage_amount"]){
                            message.push("Please select coverge amount for Property Damage");
                            flag = false
                        }
                        if(!data["premium_amount"]){
                            message.push("Please Enter Premium amount for Property Damage");
                            flag = false
                        }
                    }else if(data.id===3){
                        if(!data["coverage_amount"]){
                            message.push("Please select coverge amount for Personal Accident");
                            flag = false
                        }
                        if(data["premium_amount"] === ""){
                            let premiumAmtValidation = true;
                            if(data['milestone_coverage_amount_limit'] || data['oona_coverage_amount_limit']){
                                if(data['milestone_coverage_amount_limit'] === data['coverage_amount'] || data['oona_coverage_amount_limit'] === data['coverage_amount']){
                                    premiumAmtValidation = false;
                                }
                            }

                            if(premiumAmtValidation || applicationType===2){

                                message.push("Please Enter Premium amount for Personal Accident");
                                flag = false
                            }
                        }
                        if(!data["seat_capacity"]){
                            message.push("Please select seat capacity for Personal Accident");
                            flag = false
                        }
                    }
                }   
                        
            }    
        }
        return new Promise((resolve)=>resolve({checkValue:flag,message }));
    }

    const isValidPartner = async ()=>{
        let flag = true;  
        let reqParam = callServiceToCreateLead(sectionList) 
        let params: any = { 
            vehicle_usage: reqParam.vehicle_usage,
            insurance_type: reqParam.insurance_type,
            rule_engine: applicationType,
            total_fmv: reqParam.total_fmv,
            lead_type: apptype === APP_TYPE_WITH_UCF ? insuranceState?.activeLoanID?.lead_type : LEAD_TYPE_WEB_APP,
            role_id: 3,
            apptype: apptype,
            addon_detail: reqParam.addon_detail,
            vehicle_type: reqParam.vehicle_type,
            ...extraField ,
            make_year: reqParam.make_year,
            proposed_fmv: reqParam.proposed_fmv
        }
        if (params.insurance_type === INS_TYPE_CTPL) {
            params['tenure'] = reqParam.tenure
        } 
        setLoading(true);
        let result = await InsuranceService.getAddonDetails(params)  
        setLoading(false);
        let message:any = null;
        if (result?.data?.data) { 
            //save total premium in state for installment validation
            let { total_premium } = result.data.data
            dispatch(saveInsurance({
                ...insuranceState.createInsurance,
                total_premium: +total_premium
            }));
        } else {
            message = result.data.message; 
            flag = false
        } 
        return new Promise((resolve)=>resolve({checkValue:flag,message }));
    }


    const onSaveNextPress = async () => {
        if (subRoute === 'insurance_form') {
            let sectionItem = sectionList[0]
            if (isValidAllField(sectionItem)) {
                let {checkValue,message}:any = await isValidAddon();   
                let checkBaseRate = await validateBaseIssueRate()
                if(checkValue && checkBaseRate){
                    let response = callServiceToCreateLead(sectionList);
                    setInsuranceType(+response?.insurance_type);
                    if(+response?.insurance_type === INS_TYPE_CTPL) resetPaymentTermTenure(INS_TYPE_CTPL)
                    vehicleDetailsBlockField(response.insurance_type);
                    (page < 2) && setPage(2)
                    navigate('/create-insurance-policy?step=insurance_quote');
                    saveDetails(sectionList) //update form in state
                }else{
                    for(let msg of message){
                        toast.error(msg);
                    }
                }
            }
        } else if (subRoute === 'insurance_quote') {
            if (extraField.partner_id) { 
                let {checkValue,message}:any = await isValidPartner();
                if(checkValue){
                    (page < 3) && setPage(3)
                    navigate('/create-insurance-policy?step=personal_details');
                    saveDetails(sectionList) //update form in state
                    toast.error(message);
                }else{
                    toast.error(message);
                } 
            } else {
                toast.error("Please Select Partner!");
            }
        } else if (subRoute === 'personal_details') {
            setTrackSubmitStatus(true);
            let sectionItem = sectionList[2]
            if (isValidAllField(sectionItem)) {
                (page < 4) && setPage(4)
                setTrackSubmitStatus(false);
                navigate('/create-insurance-policy?step=other_details');
                saveDetails(sectionList) //update form in state
            } else setValue(value + 1)
        } else if (subRoute === 'other_details') {
            let sectionItem = sectionList[3];
            if(insuranceType===INS_TYPE_CTPL){
                let plate_number = getFieldItem(sectionItem['blocks'],'plate_number');
                let mv_number = getFieldItem(sectionItem['blocks'],'mv_number');
                if(!plate_number.value && !mv_number.value){
                    plate_number['error_message'] = "Please enter Plate no or MV file no";
                    mv_number['error_message'] = "Please enter Plate no or MV file no";
                    setValue(value + 1)
                }else{
                    if (isValidAllField(sectionItem)) {
                        submitLead();
                        saveDetails(sectionList) //update form in state
                    }
                }
            }else{
                if (isValidAllField(sectionItem)) {
                    submitLead();
                    saveDetails(sectionList) //update form in state
                }
            }
        }
    }

    const submitLead = async () => {
        let response = callServiceToCreateLead(sectionList);
        let reqParam = {
            ...extraField, ...response,
            lead_type: 3, apptype: apptype,
            user_id: userID, rule_engine: applicationType,
            // need to dynamic
            role_id: 1, 
            source: 2 //adding lead source as web
            // end need to dynamic  
        }

        if(apptype === APP_TYPE_WITH_UCF) {
            let loanData = insuranceState?.activeLoanID
            reqParam = {
                ...reqParam,
                lead_type: loanData?.lead_type,
                documents: loanData?.documents,
                user_id: loanData?.lead_type === 1 ? loanData?.so_id : loanData?.dealer_id,
                role_id: loanData?.lead_type === 1 ? 6 : 0,
                dealer_id: loanData?.dealer_id, 
                dealer_name: loanData?.dealer_name, 
                ucf_lead_id: activeLoanID,
                lead_on_behalf_user_id: userID,
                source: 1
            }

            //delete if expected installment date exists
            delete reqParam['expected_installment']
        }

        if(apptype === APP_TYPE_RENEWAL) {
            let state = insuranceState?.leadDetail
            let { lead_id } = state

            reqParam = {
                ...reqParam,
                source: 2,
                parent_id: lead_id
            }
        }

        if(reqParam['payment_term'] !== PAYMENT_TERM_INSTALLMENT) {
            delete reqParam['payment_tenure']
        }

        setLoading(true)
        setFinalData(reqParam);
        //trim extra space from payload
        if (reqParam?.referrer_name) {
            reqParam.referrer_name = reqParam.referrer_name.trim();
        }
        
        const fields = ENCRYPTED_FIELDS;
        fields.forEach((field:any) => {
            if (reqParam?.[field]) {
                reqParam[field] = EncodetoBase64(reqParam[field]);
            }
        });

        //convert expected_installment obj to array
        if(reqParam.expected_installment) {
            let expectedInstallment = []
            for(let key in reqParam?.expected_installment) {
                expectedInstallment.push(reqParam.expected_installment[key])
            }
            reqParam.expected_installment = expectedInstallment
        }
       
       let result = await InsuranceService.createLead(reqParam);
  
        setLoading(false)
        if (result?.data?.data) {
            setLeadhashId(result.data.data.hashId);
            navigate('/create-insurance-policy?step=doc_details&lead_id=' + result.data.data.hashId);
            setPage(5)
        }
        else {
            toast.error(result.data.message);
        }
    }


    const onVersionSelect = (data: any) => {
        if (sectionList?.length >= 4) {
            const element = sectionList[3]
            let blockList = element['blocks'] as []
            if (blockList?.length) {
                blockList.forEach((each: any, index) => {
                    each.fields.forEach((item: any) => {
                        if (item.name === 'seat_capacity') {
                            if (data.seating_capacity) {
                                setVersionSeat(data.seating_capacity)
                                item['value'] = data.seating_capacity?.toString()
                                item['is_enable'] = false
                            }
                            else {
                                setVersionSeat(0)
                                item['value'] = ''
                                item['is_enable'] = true
                                if(apptype !== APP_TYPE_RENEWAL) {
                                    item['not_set'] = true
                                }
                            }
                        }
                        if (item.name === 'transmission') {
                            if (data.transmission) {
                                item['value'] = data.transmission
                                item['is_enable'] = false
                            }
                            else {
                                item['value'] = ''
                                item['is_enable'] = true
                            }
                        }
                        if (item.name === 'fuel_type') {
                            if (data.fuel_type) {
                                item['value'] = data.fuel_type
                                item['is_enable'] = false
                            }
                            else {
                                item['value'] = ''
                                item['is_enable'] = true
                            }
                        }
                    })
                })
            }
        }
    }
    // For goin one step back
    const onBackfunction = () => {

        const subRouteMap:any = {
            'insurance_quote': 'insurance_form',
            'personal_details': 'insurance_quote',
            'other_details': 'personal_details',
            'doc_details': 'other_details'
        };
        let previousSubRoute:any = subRouteMap[subRoute];
        if (previousSubRoute) {
            previousSubRoute += routeLeadId ? '&lead_id='+routeLeadId : ''
            navigate(`/create-insurance-policy?step=${previousSubRoute}`);
        }
    
    } 

    const vehicleDetailsBlockField = (insurance_type:number) => { 
        let tempList:any = sectionList;
        tempList.forEach((sectionItem: any) => {
            sectionItem?.blocks?.forEach((blockItem: any,) => {
                blockItem?.fields?.forEach((fieldItem: any) => {
                    if (fieldItem.name === 'mv_number') {
                        fieldItem['is_show']=(insurance_type===INS_TYPE_COMPREHENSIVE)?false:true
                    }
                    if (fieldItem.name === 'plate_number') {
                        fieldItem['is_required']=(insurance_type===INS_TYPE_COMPREHENSIVE)?true:false
                    }
                })
            })
        })
        setSectionList([...tempList]);
    }

    const getFieldItem = (blocklist:any,key: any) => {
        let filterItem = blocklist[0].fields?.filter((item: any) => item.name === key)?.[0]
        return filterItem
    }

    const validateBaseIssueRate = async () => {
        let sectionItem = sectionList[0].blocks[0].fields
        let updatedParams: any = { apptype, rule_engine: applicationType }
        let keysToSend = ['vehicle_usage', 'vehicle_type', 'make_year', 'total_fmv', 'insurance_type', 'proposed_fmv']

        sectionItem.forEach((ele: any, idx: number) => {
            if (keysToSend.includes(ele.name) && ele.value) updatedParams[ele.name] = (sectionItem[idx])?.value?.value || (sectionItem[idx].value)
        })

        if(updatedParams['proposed_fmv']) updatedParams['proposed_fmv'] = +updatedParams['proposed_fmv']
        let baseIssueRate: any = (+updatedParams?.insurance_type === 1) ? await fetchBaseRate(updatedParams) : {data: {rate: true }}
        if (baseIssueRate?.data?.rate) {
            return true
        }
        return false
    }

    const setParentPage = (page: number) => setPage(page)

    const resetPaymentTermTenure = (insuranceType: any) => {
        let sections = sectionList.length > 3 && sectionList[2]
        let fieldItem = sections?.blocks[0]?.fields
        if (fieldItem?.length && insuranceType) {
            let paymentTerm = fieldItem.find((e: any) => e.name === "payment_term")
            let paymentTenure = fieldItem.find((e: any) => e.name === "payment_tenure")
            let isCTPL = insuranceType === INS_TYPE_CTPL ? true : false

            if (paymentTerm) {
                paymentTerm.value = isCTPL ? "" : paymentTerm.value
                paymentTerm.options = isCTPL ? paymentTerm?.options?.filter((e: any) => e.value !== PAYMENT_TERM_INSTALLMENT) : commonState?.common?.payment_term
            }
            if (paymentTenure) {
                paymentTenure.is_show = isCTPL ? false : paymentTenure.is_show
                paymentTenure.value = isCTPL ? "" : paymentTenure.value
            }
        }
    }

    const handleNavLinkRouting = () => {
        let { extraField, formDetails} = insuranceState.formDetails
        if(formDetails?.length) {
            setExtraField(extraField)
            return setSectionList(JSON.parse(JSON.stringify(formDetails)))
        }
    }

    const saveDetails = (sectionItem: any) => {
        let form = JSON.parse(JSON.stringify(sectionItem))
        dispatch(saveFormDetails({ formDetails: form, extraField })) //save form details
    }

    const updateSectionList = (data:any) => {
        setSectionList(data)
    }

    const onApptypeChange = (params: { apptype: number, eventKey: string }) => {
        let { apptype, eventKey } = params
        setPage(1)
        setApptype(apptype)
        setActiveTab(eventKey)
        setActiveLoanID(null)
        setActiveRenewalID(null)
        dispatch(updateRenewalState({ insuranceForm: false, customerDetails: false }))

        switch (apptype) {
            case 1: {
                getLoanApplications()
                dispatch(updateLeadDetailsInStore({}))
                break
            }

            case 2: {
                dispatch(clearLoanData({}))
                dispatch(updateLeadDetailsInStore({}))
                fetchFormConfig()
                break
            }

            case 3: {
                dispatch(clearLoanData({}))
                getRenewalApplications()
                break
            }

            case 4: {
                dispatch(clearLoanData({}))
                navigate('/create-insurance-policy-agency?step=upload_docs');
                break
            }
            default: {
                break
            }
        }
    }
  
    return (
        <div >
            <FullPageLoader show={loading} />
            <div className="container-fluid">
                <div className="top-heading financer-lead-heding">
                    <h1>Create Insurance Application</h1>
                </div>
                <div className="lead-detail-tabs">

                    {sectionList && <div className="detail-form-sec">

                        <div className="menu-tab-left-panel">
                            <div className="dealer-tab-left-panel nav">
                                <ul>
                                    {
                                        sectionList.map((sectionItem: any, index: any) => {
                                            return (
                                                <li key={index}>
                                                    {
                                                        ((page > index)) ?
                                                            <NavLink to={"/create-insurance-policy?step=" + sectionItem['key'] + (leadhashId ? '&lead_id=' + leadhashId : '')} onClick={() => handleNavLinkRouting()} className={(index + 1) < page ? "completed" : ""} >
                                                                <span className="img-type"></span>
                                                                {sectionItem['title']}</NavLink>
                                                            :
                                                            <a href="#" onClick={(e) => e.preventDefault()}>
                                                                <span className="img-type"></span>
                                                                {sectionItem['title']}
                                                            </a>

                                                    }

                                                </li>
                                            )
                                        }
                                        )
                                    }

                                </ul>

                            </div>
                        </div>

                        <div className="route-files">

                            <div className=" insurance-create-outer relative " style={{ "display": "block" }}>

                                <div className="loan-expect-left-panle">

                                    <div className="loan-form">

                                        <div className={`loan-form-filed-scroll ${(searchParams?.get('step') === 'insurance_quote' || searchParams?.get('step') === 'doc_details') ? `quote-scroll` : ''}`}>

                                            {subRoute === "insurance_form"
                                                && <div className="loan-filed-sec">
                                                    <div className={apptype === 2 ? 'm-b-20': 'm-b-20'}>
                                                        <div className="heading-dp-txt">
                                                            <span>Insurance Type</span>
                                                        </div>
                                                        <div className='common-appliction-outer'> 
                                                            <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
                                                                <Nav variant="pills" className={"flex-column tab-line"}>
                                                                    <div className="tab-list w-100">
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="first" onClick={() => onApptypeChange({ apptype: 2, eventKey: 'first' })}>Standalone</Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="second" onClick={() => onApptypeChange({ apptype: 1, eventKey: 'second' })}>With UCF</Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="third" onClick={() => onApptypeChange({ apptype: 3, eventKey: 'third' })}>Renewal</Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="forth" onClick={() => onApptypeChange({ apptype: 4, eventKey: 'forth' })}>Agency</Nav.Link>
                                                                        </Nav.Item>
                                                                    </div>

                                                                </Nav>
                                                            </Tab.Container>
                                                        </div>
                                                        {
                                                            apptype === APP_TYPE_WITH_UCF
                                                            &&
                                                            <div className="form-group-bx">
                                                                <div className="row">
                                                                    <ReactSelect
                                                                        options={loanApplicationList}
                                                                        value={loanApplicationList.find((e: any) => e.value === activeLoanID) || null}
                                                                        handleChange={(e: any, fieldKey: string) => handleLeadIDSelection('loan', e, fieldKey)}
                                                                        placeholder="Loan ID"
                                                                        fieldKey="loan_id"
                                                                        // isDisabled={!isComprensive ? true : false}
                                                                        fieldSetClassName="col-md-4 p-lg-t"
                                                                        errorField={(apptype === APP_TYPE_WITH_UCF && errors.loan_id) ? "Please select Loan ID" : ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }

                                                        {
                                                            apptype === APP_TYPE_RENEWAL
                                                            &&
                                                            <div className="form-group-bx">
                                                                <div className="row">
                                                                    <ReactSelect
                                                                        options={renewalAppList}
                                                                        value={renewalAppList.find((e: any) => e.value === activeRenewalID) || null}
                                                                        handleChange={(e: any, fieldKey: string) => handleLeadIDSelection('renewal', e, fieldKey)}
                                                                        placeholder="Renewal Application ID"
                                                                        fieldKey="renewal_lead_id"
                                                                        // isDisabled={!isComprensive ? true : false}
                                                                        fieldSetClassName="col-md-4 p-lg-t"
                                                                        errorField={(apptype === APP_TYPE_RENEWAL && errors.renewal_id) ? "Please select Renewal Application ID" : ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="heading-dp-txt">
                                                        <span>Common Application Form</span>

                                                    </div>
                                                    <div className='common-appliction-outer col-md-12 m-xl-b radio-btn'>
                                                        <div className='m-lg-r'>
                                                            Application Type*
                                                        </div>

                                                        <div className="custom-control custom-checkbox col-md-3 ">
                                                            <input checked={applicationType === 1} id="Individual" type="radio" className="custom-control-input " name="Buyer Type" onChange={() => onApplicationTypeValueChanged(1)} />
                                                            <label className="custom-control-label">With Rule Engine </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox col-md-3 ">
                                                            <input checked={applicationType === 2} id="Individual" type="radio" className="custom-control-input " name="Buyer Type" onChange={() => onApplicationTypeValueChanged(2)} />
                                                            <label className="custom-control-label">Without Rule Engine </label>
                                                        </div>
                                                    </div>
                                                    <InsuranceForm
                                                        sectionItem={sectionList[0]}
                                                        applicationType={applicationType}
                                                        onVersionSelect={onVersionSelect}
                                                        versionSeat={versionSeat}
                                                        apptype={apptype}
                                                        handlePaymentTermTenure={resetPaymentTermTenure}
                                                        activeLoanID={activeLoanID}
                                                        setExtraField={setExtraField}
                                                        extraField={extraField}
                                                        setLoading={setLoading}
                                                    />

                                                </div>}

                                            {
                                                subRoute === "insurance_quote" &&
                                                <QuotesList params={callServiceToCreateLead(sectionList)} setExtraField={setExtraField} applicationType={applicationType} extraField={extraField} setParentPage={setParentPage} backFunc={onBackfunction} nextFunc={onSaveNextPress} apptype={apptype} activeLoanID={activeLoanID}/>

                                            }

                                            {
                                                subRoute === "personal_details" &&
                                                <PersonalDetailsFormComponent
                                                    onChangeText={onChangeFieldItem}
                                                    sectionItem={sectionList[2]}
                                                    stateCityList={(commonState.stateWithCity) ? commonState.stateWithCity : []}
                                                    insuranceType={insuranceType}
                                                    apptype={apptype}
                                                    activeLoanID={activeLoanID}
                                                    sectionItemAll={sectionList}
                                                    saveDetails={saveDetails}
                                                />
                                            }

                                            {
                                                subRoute === "other_details" &&
                                                <OtherDetailsFormComponent
                                                    insuranceType={insuranceType}
                                                    sectionItem={sectionList[3]}
                                                    sectionItemAll={sectionList}
                                                    apptype={apptype}
                                                    updateSectionList = {updateSectionList}
                                                    setLoading={setLoading}
                                                />
                                            }

                                            {
                                                subRoute === "doc_details" ?
                                                    <DocumentForm leadId={leadhashId} onBackfunction = {onBackfunction} finalData= {finalData} apptype = {apptype}/>
                                                    :
                                                    <div className="loan-filed-sec">
                                                       

                                                            {
                                                                !searchParams.get('lead_id') && searchParams.get('step') !== "insurance_quote" &&
                                                                <div className="col-md-12 f-right t-right">
                                                                   {subRoute !== 'insurance_form' &&  
                                                                    <button className="btn-line m-sm-r" onClick ={onBackfunction} >Back </button>
                                                                } 
                                                                {
                                                                    leadhashId ? null :
                                                                    <button className="btn-primary " onClick={onSaveNextPress}>Save & Next</button> 
                                                                }
                                                                    
                                                                </div>
                                                            }

                                                       
                                                    </div>
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>}



                    <div className="mark-lost-popup">

                    </div>
                </div>

            </div>

        </div>
    )
}

export default CreateInsurancePolicy
