import ReactSelect from '@common/ReactSelect';
import { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { useSelector } from 'react-redux';
import { getMasterConfig } from '@src/store/slices/common';
import { useDispatch } from 'react-redux';
import { saveAgencyPartner } from '@src/store/slices/agency';
import { isAllSubRangesDefined } from '@src/config/helper';
import { toast } from 'react-toastify';
import ConfirmPopup from '@common/ConfirmPopUp';
import FullPageLoader from '@common/FullPageLoader';
import { REGEX_DECIMAL_VALIDATION, REGEX_DECIMAL_VALIDATION_8 } from '@src/config/constant';

export default function AddEditInsuranceAgency(props: any) {

    const initialFormFields = [{ vehicle_usage: 0, vehicle_type: 0, partner_id: 0, default_base_rate: '', min_base_rate: '', max_base_rate: '', referralFeeConfig: [{ min_rate_range: '', max_rate_range: '', referral_fee_scheme: '', net_rate_value: '', referral_fee_percentage:'' ,bi_pd_adjustment: '' }] }];

    const [partnerMapping, setPartnerMapping] = useState<any>(initialFormFields);

    const commonState = useSelector((state: any) => state.common);
    const insurance = useSelector((state: any) => state.insurance)
    const dispatch: any = useDispatch();
    const [showConfirmation, setShowConfirmation] = useState<any>({ parentkey: null, childKey: null, action: false });
    const [partnerErrFields, setPartnerErrFields] = useState<any>({})
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (Object.keys(commonState?.common).length === 0) {
            dispatch(getMasterConfig());
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props?.partnerMapping?.length) {
            setPartnerMapping(props?.partnerMapping)
        }

        // eslint-disable-next-line
    }, [props?.partnerMapping]);

    const handleChange = (name: any, event: any, parentKey: number, childKey: any) => {

        let selectedValue = (["vehicle_usage", "vehicle_type", "partner_id", "referral_fee_scheme"].includes(name)) ? event.value : event.target.value;

        let formArray = JSON.parse(JSON.stringify(partnerMapping))

        if (['default_base_rate', 'min_base_rate', 'max_base_rate', 'min_rate_range', 'max_rate_range', 'net_rate_value', 'referral_fee_percentage' ,'bi_pd_adjustment','referral_fee_percentage_for_ctpl'].includes(name) 
            && (
                (selectedValue !== '' && !(REGEX_DECIMAL_VALIDATION_8.test(selectedValue) || REGEX_DECIMAL_VALIDATION.test(selectedValue))) 
                || selectedValue > 100
            )) {
            return false;
        }
        
        if (childKey !== null) {
            formArray[parentKey]['referralFeeConfig'][childKey][name] = selectedValue;

            // Reset values if 'referral_fee_scheme' is updated
            if (name === "referral_fee_scheme") {
                if ([1].includes(+selectedValue)) {
                    // Reset 'net_rate_value' to 0
                    formArray[parentKey]['referralFeeConfig'][childKey]['net_rate_value'] = '';
                } else {
                    // Reset 'referral_fee_percentage' to 0
                    formArray[parentKey]['referralFeeConfig'][childKey]['referral_fee_percentage'] = '';
                }
            }

        } else {

            formArray[parentKey][name] = selectedValue;
            // Reset vehicle_type if vehicle_usage is updated
            if (name === "vehicle_usage") {
                formArray[parentKey]["vehicle_type"] = null;
            }

        }

        setPartnerErrFields((prev: any)=> ({...prev, [parentKey] : {...prev?.[parentKey], [name]: "", referralSchemeErr: prev?.[parentKey]?.['referralSchemeErr']?.map((elm:any)=> {
            if(elm[name]){
                elm[name] = ""
            }
            return elm
        }) }}))
        setPartnerMapping([...formArray])
    }

    const addNewRange = (parentKey: number) => {

        let formArray = JSON.parse(JSON.stringify(partnerMapping))

        formArray[parentKey]['referralFeeConfig'] = [...formArray[parentKey]['referralFeeConfig'], ...initialFormFields[0]['referralFeeConfig']]
        setPartnerMapping([...formArray])

    }

    const handleSubmit = (e: any, partnerKey: number) => {
        e.preventDefault();
    
        let getLocalPartnerMapping = partnerMapping
        let selectPartner = partnerMapping[partnerKey]


        // IF NO ERROR
        if (!validPartnerMapping(selectPartner, partnerKey)) {

            // Check if max base rate is greater than min base rate
            if (selectPartner?.max_base_rate <= +selectPartner?.min_base_rate) {
                toast.error(`Max Base Rate should be greater than Min Base Rate`);
                return false;
            }
            // Check if the default base rate is within the range of min and max base rate
            if (+selectPartner?.default_base_rate <= +selectPartner?.min_base_rate || +selectPartner?.default_base_rate > +selectPartner?.max_base_rate) {
                toast.error(`Default Base Rate should be greater than ${+selectPartner?.min_base_rate} and less than equals to ${selectPartner?.max_base_rate}`);
                return false;
            }

            // let formData = serializeForm(event.target, { hash: true });
            let generateRanges = selectPartner?.referralFeeConfig?.map((elm: any) => ([+elm.min_rate_range, +elm.max_rate_range]))

            // Generate Range for bi_pd_adjustment validation
            let biPdValidation = selectPartner?.referralFeeConfig?.map((elm: any) => ({
                bi_pd_adjustment: +elm.bi_pd_adjustment,
                referral_fee_scheme: +elm.referral_fee_scheme,
                net_rate_value: +elm.net_rate_value,
                referral_fee_percentage : +elm.referral_fee_percentage,
                net_rate_range_validation : commonState?.common?.net_rate_range_validation,
                bi_pd_range_validation : commonState?.common?.bi_pd_range_validation,
                ref_fee_per_range_validation : commonState?.common?.ref_fee_per_range_validation,
                referral_fee_percentage_for_ctpl : +selectPartner.referral_fee_percentage_for_ctpl,
            }));

            let rangeCombinationCheck = isAllSubRangesDefined(generateRanges, +selectPartner?.min_base_rate, +selectPartner?.max_base_rate);

            let validationBiPdValue = validateBiPdAdjustment(biPdValidation);

            // return false;
            if (!rangeCombinationCheck) {
                toast.error(`Referral scheme ranges are invalid, please specify all applicable ranges between ${selectPartner?.min_base_rate}-${selectPartner?.max_base_rate}`)
                return false;
            }

            if (!validationBiPdValue.isValid) {
                const { errorRange, errorType } = validationBiPdValue;
                if (errorRange){
                    if (errorType === 1) {
                        // Show error for net_rate_value
                        toast.error(
                            `Net Rate Value is invalid. It should lie in between ${errorRange.min}-${errorRange.max}. Current value: ${errorRange.net_rate_value}.`
                        );
                    } else if (errorType === 2) {
                        // Show error for BI/PD Adjustment
                        toast.error(
                            `BI/PD Adjustment is invalid. It should lie in between ${errorRange.min}-${errorRange.max}. Current value: ${errorRange.bi_pd_adjustment}.`
                        );
                    }else if (errorType === 3){
                        toast.error(
                            `Referral Fee Percentage is invalid. It should lie in between ${errorRange.min}-${errorRange.max}. Current value: ${errorRange.referral_fee_percentage}.`
                        );
                    }else if (errorType === 4){
                        toast.error(
                            `Referral Fee Percentage for CTPL is invalid. It should lie in between ${errorRange.min}-${errorRange.max}. Current value: ${errorRange.referral_fee_percentage_for_ctpl}.`
                        );
                    }
                
                }
                return false;
            }

            setLoading(true)
            selectPartner['referral_fee_scheme_for_ctpl'] = 1;
            saveAgencyPartner({ agency_id: props.agency_id, partner_mapping: selectPartner }).then((res: any) => {
                setLoading(false)

                if (res.data) {
                    toast.success(res.message)
                    getLocalPartnerMapping[partnerKey] = res.data?.partner_mapping
                    setPartnerMapping(getLocalPartnerMapping)
                    const validRecords = getLocalPartnerMapping.filter((record: { id?: number }) => record.id !== undefined && record.id !== null).length;
                    props.updatePartnerCount(validRecords);
                }
            }).catch(err => setLoading(false))
        }
    }

    /**
     * VALIDATE PARTNER MAPPING DATA
     * @param partnerData 
     * @returns 
     */
    const validPartnerMapping = (partnerData: any, partnerKey: any) => {
        let errorFields: any = {}

        if (!partnerData?.vehicle_usage) {
            errorFields['vehicle_usage'] = "Vehicle Usage is required";
        }
        if (!partnerData?.vehicle_type) {
            errorFields['vehicle_type'] = "Vehicle Type is required";
        }
        if (!partnerData?.partner_id) {
            errorFields['partner_id'] = "Insurance Partner is required";
        }
        if (!+partnerData?.default_base_rate) {
            errorFields['default_base_rate'] = "Default Base Issue Rate is required";
        }
        if (!+partnerData?.min_base_rate) {
            errorFields['min_base_rate'] = "Min Base Issue Rate is required";
        }
        if (!+partnerData?.max_base_rate) {
            errorFields['max_base_rate'] = "Max Base Issue Rate is required";
        }

        if (partnerData?.referralFeeConfig) {
            for (let [idx, scheme] of partnerData?.referralFeeConfig?.entries()) {
                let schemeErr: any = {};

                if (!+scheme?.min_rate_range) {
                    schemeErr['min_rate_range'] = "Issue Min Rate Range is required";
                }
                if (!+scheme?.max_rate_range) {
                    schemeErr['max_rate_range'] = "Issue Max Rate Range is required";
                }
                if (!scheme?.referral_fee_scheme) {
                    schemeErr['referral_fee_scheme'] = "Referral Fee Scheme is required";
                }
                if (!+scheme?.net_rate_value && scheme?.referral_fee_scheme !== 1) {
                    schemeErr['net_rate_value'] = "Net Rate Value is required";
                }
                if (+scheme?.net_rate_value && scheme?.referral_fee_scheme !== 1 && +partnerData?.default_base_rate <= +scheme?.net_rate_value) {
                    schemeErr['net_rate_value'] = "Net Rate should be less than default rate";
                }
                if (!+scheme?.referral_fee_percentage && scheme?.referral_fee_scheme === 1) {
                    schemeErr['referral_fee_percentage'] = "Referral Fee Percentage Value is required";
                }
                if ([3,4].includes(+scheme?.referral_fee_scheme)  && !+scheme?.bi_pd_adjustment) {
                    schemeErr['bi_pd_adjustment'] = "BI/PD Adjustment is required";
                }


                if (Object.keys(schemeErr)?.length) {
                    if (!errorFields['referralSchemeErr']) errorFields['referralSchemeErr'] = [];
                    errorFields['referralSchemeErr'][idx] = schemeErr;
                }
            }
        }
        if (Object.keys(errorFields)?.length) setPartnerErrFields({[partnerKey]: errorFields});

        return Object.keys(errorFields)?.length ? true : false;
    }

    const addNewPartnerMapping = () => {

        let formArray = JSON.parse(JSON.stringify(partnerMapping))

        formArray.push(initialFormFields[0])
        setPartnerMapping([...formArray])
    }

    const getPartnerLogo = (partnerId: any) => {
        let partner: any = commonState.common.partner_list?.find((partner: any) => +partner.value === +partnerId)
        return partner?.logo
    }

    const getPartnerName = (partnerId: any) => {
        let partner: any = commonState.common.partner_list?.find((partner: any) => +partner.value === +partnerId)
        return partner?.label
    }

    const getPartnerReferralSchemeOptions = (partnerKey: any, childKey: any) => {
        let getLocalPartnerMapping = partnerMapping;

        let getChildSelectedSchemes = getLocalPartnerMapping[partnerKey]?.['referralFeeConfig']?.map((el: any) => +el.referral_fee_scheme) || []

        let availableOptions = commonState?.common?.referral_fee_scheme?.filter((elm: any) => !getChildSelectedSchemes.includes(+elm.value)) || []
        // return availableOptions || [];

        return commonState?.common?.referral_fee_scheme || []
    }

    /**
     * Filter valid unique vehicle type 
     * @param partnerKey 
     * @returns 
     */
    const getValidVehicleType = (partnerKey: any) => {
        let getLocalPartnerMapping = partnerMapping;

        let getSelectedVehicleType = getLocalPartnerMapping?.map((el: any) => +el.vehicle_type) || []

        let availableOptions = commonState?.common?.vehicle_type?.filter((elm: any) => (!getSelectedVehicleType.includes(+elm.value) && elm.vehicle_usage == getLocalPartnerMapping?.[partnerKey]?.['vehicle_usage'])) || []

        return getLocalPartnerMapping?.[partnerKey]?.['vehicle_usage'] ? availableOptions : [];

        // return commonState?.common?.referral_fee_scheme || []
    }

    const removeReferralScheme = (parentKey: any, childKey: any) => {
        let getLocalPartnerMapping = partnerMapping;

        getLocalPartnerMapping[parentKey]['referralFeeConfig'] = getLocalPartnerMapping[parentKey]?.['referralFeeConfig']?.filter((el: any, key: number) => key !== childKey);

        setPartnerMapping([...getLocalPartnerMapping])

    }

    const confirmNo = () => {
        setShowConfirmation({ parentkey: null, childKey: null, action: false });
    }

    const confirmYes = async () => {

        removeReferralScheme(showConfirmation?.parentKey, showConfirmation?.childKey);

        setShowConfirmation({ parentkey: null, childKey: null, action: false });


    }

    const validateBiPdAdjustment = (ranges: any[]) => {
        // Iterate through each range to validate
        for (const range of ranges) {
            const {
                bi_pd_adjustment,
                referral_fee_scheme,
                net_rate_value,
                referral_fee_percentage,
                net_rate_range_validation,
                bi_pd_range_validation,
                ref_fee_per_range_validation,
                referral_fee_percentage_for_ctpl
            } = range;
            // Validate Net Value 
    
            const [netRateMin, netRateMax] = net_rate_range_validation.split('-').map(Number);
            const [biPdMin, biPdMax] = bi_pd_range_validation.split('-').map(Number);
            const [refFeePerMin, refFeePerMax] = ref_fee_per_range_validation.split('-').map(Number);
    
            if(referral_fee_percentage_for_ctpl){
                if(referral_fee_percentage_for_ctpl < refFeePerMin || referral_fee_percentage_for_ctpl > refFeePerMax){
                    return {
                        isValid: false,
                        errorRange: { min: refFeePerMin, max: refFeePerMax, net_rate_value: net_rate_value,bi_pd_adjustment: bi_pd_adjustment,referral_fee_percentage:referral_fee_percentage,referral_fee_percentage_for_ctpl:referral_fee_percentage_for_ctpl},
                        errorType : 4
                    };
                }
            }
    
            if(referral_fee_scheme !== 1){
                if (net_rate_value < netRateMin || net_rate_value > netRateMax) {
                    return {
                        isValid: false,
                        errorRange: { min: netRateMin, max: netRateMax, net_rate_value: net_rate_value,bi_pd_adjustment: bi_pd_adjustment},
                        errorType : 1
                    };
                }
            }else{
                if(referral_fee_percentage < refFeePerMin || referral_fee_percentage > refFeePerMax){
                    return {
                        isValid: false,
                        errorRange: { min: refFeePerMin, max: refFeePerMax, net_rate_value: net_rate_value,bi_pd_adjustment: bi_pd_adjustment,referral_fee_percentage:referral_fee_percentage},
                        errorType : 3
                    };
                }
            }
            // Only validate for referral_fee_scheme === 3 or 4
            if (referral_fee_scheme === 3 || referral_fee_scheme === 4) {
                if (bi_pd_adjustment < biPdMin || bi_pd_adjustment > biPdMax) {
                    // Return the invalid range details
                    return {
                        isValid: false,
                        errorRange: { min: biPdMin, max: biPdMax, net_rate_value: net_rate_value ,bi_pd_adjustment: bi_pd_adjustment},
                        errorType : 2
                    };
                }
            }
        }
    
        // All ranges are valid
        return {
            isValid: true,
            errorRange: null,
            errorType : 0
        };
    }

    return (
        <>
            <div className="user-screen-popup user-managent-add-user-popup insurance-partner-mapping m-xl-t">
                <FullPageLoader show={loading} />
                <div className="row">

                    {
                        partnerMapping.map((elm: any, parentKey: number) =>
                            <div className="add-user-data col-md-12" key={"parentForm" + (parentKey + 1)}>
                                <form onSubmit={(e) => handleSubmit(e, parentKey)}>

                                    <Accordion allowZeroExpanded>

                                        <AccordionItem >
                                            <AccordionItemHeading className='pointer'>
                                                <AccordionItemButton>
                                                    <span>Insurance Partner Mapping {parentKey + 1}</span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>


                                                <div className="row">
                                                    <div className=" role-status-outer w-100">
                                                        <div className="col-md-2">
                                                            <fieldset className="form-filed">
                                                                <div className="material">
                                                                    <ReactSelect
                                                                        options={commonState?.common?.vehicle_usage || []}
                                                                        value={
                                                                            commonState?.common?.vehicle_usage?.find((pt: any) => pt.value === elm['vehicle_usage']) || null
                                                                        }
                                                                        handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e, parentKey, null)}
                                                                        placeholder="Vehicle Usage*"
                                                                        fieldKey="vehicle_usage"
                                                                        name="vehicle_usage"
                                                                        errorField={partnerErrFields?.[parentKey]?.vehicle_usage}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <fieldset className="form-filed">
                                                                <div className="material">
                                                                    <ReactSelect
                                                                        options={getValidVehicleType(parentKey)}

                                                                        // options={commonState?.common?.vehicle_type?.filter((ls: any) => ls.vehicle_usage === elm['vehicle_usage']) || []}
                                                                        value={
                                                                            commonState?.common?.vehicle_type?.find((pt: any) => pt.value === elm['vehicle_type']) || null
                                                                        }
                                                                        handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e, parentKey, null)}
                                                                        placeholder="Vehicle Type*"
                                                                        fieldKey="vehicle_type"
                                                                        name="vehicle_type"
                                                                        errorField={partnerErrFields?.[parentKey]?.vehicle_type}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <fieldset className="form-filed">
                                                                <div className="material">
                                                                    <ReactSelect
                                                                        options={commonState?.common?.partner_list || []}
                                                                        value={
                                                                            commonState?.common?.partner_list?.find((pt: any) => pt.value === elm['partner_id']) || null
                                                                        }
                                                                        handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e, parentKey, null)}
                                                                        placeholder="Insurance Partner*"
                                                                        fieldKey="partner_id"
                                                                        name="partner_id"
                                                                        errorField={partnerErrFields?.[parentKey]?.partner_id}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <fieldset className="">
                                                                <div className="material">
                                                                    <input id="insurance_type" type="text" placeholder=" " name="insurance_type" className="form-input" value='Comprehensive' readOnly/>
                                                                    <label data-label="Insurance Type" className="form-label"></label>
                                                                    <span className="error-txt">
                                                                        {partnerErrFields?.insurance_type}
                                                                    </span>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <fieldset className="">
                                                                <div className="material">

                                                                    <input id="default_base_rate" type="text" placeholder=" " name="default_base_rate" className="form-input" value={elm.default_base_rate}
                                                                        onChange={(e: any) => handleChange('default_base_rate', e, parentKey, null)}
                                                                    />
                                                                    <label data-label="Default Base Issue Rate*" className="form-label"></label>
                                                                    <span className="error-txt">
                                                                        {partnerErrFields?.[parentKey]?.default_base_rate}
                                                                    </span>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <fieldset className="">
                                                                <div className="material">
                                                                    <input id="min_base_rate" type="text" placeholder=" " name="min_base_rate" className="form-input" value={elm.min_base_rate}
                                                                        onChange={(e: any) => handleChange('min_base_rate', e, parentKey, null)}
                                                                    />
                                                                    <label data-label="Min Base Issue Rate*" className="form-label"></label>
                                                                    <span className="error-txt">
                                                                        {partnerErrFields?.[parentKey]?.min_base_rate}
                                                                    </span>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <fieldset className="">
                                                                <div className="material">
                                                                    <input id="max_base_rate" type="text" placeholder=" " name="max_base_rate" className="form-input" value={elm.max_base_rate}
                                                                        onChange={(e: any) => handleChange('max_base_rate', e, parentKey, null)}
                                                                    />
                                                                    <label data-label="Max Base Issue Rate*" className="form-label"></label>
                                                                    <span className="error-txt">
                                                                        {partnerErrFields?.[parentKey]?.max_base_rate}
                                                                    </span>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                    <div className=" role-status-outer w-100">
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-2">
                                                            <fieldset className="">
                                                                <div className="material">
                                                                    <input id="insurance_type" type="text" placeholder=" " name="insurance_type" className="form-input" value='CTPL' readOnly/>
                                                                    <label data-label="Insurance Type" className="form-label"></label>
                                                                    <span className="error-txt">
                                                                        {partnerErrFields?.insurance_type}
                                                                    </span>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-md-2">
                                                                <ReactSelect
                                                                    options={getPartnerReferralSchemeOptions(parentKey, 'referral_fee_scheme')}
                                                                    value={
                                                                        commonState?.common?.referral_fee_scheme?.find((pt: any) => pt.value === 1) || null
                                                                    }
                                                                    handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e, parentKey, null)}
                                                                    placeholder=""
                                                                    fieldKey="referral_fee_scheme_for_ctpl"
                                                                    name="referral_fee_scheme_for_ctpl"
                                                                    isDisabled={true}
                                                                />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <fieldset className="">
                                                                <div className="material">
                                                                    <input
                                                                        id="referralFeePercentage"
                                                                        type="text"
                                                                        placeholder=" "
                                                                        name="referral_fee_percentage_for_ctpl"
                                                                        className="form-input"
                                                                        value={elm.referral_fee_percentage_for_ctpl}
                                                                        onChange={(e: any) => handleChange('referral_fee_percentage_for_ctpl', e, parentKey, null)}
                                                                    />
                                                                    <label data-label="Referral Fee Percentage" className="form-label"></label>
                                                                    <span className="error-txt">
                                                                        {partnerErrFields?.referral_fee_percentage_for_ctpl}
                                                                    </span>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                    </div>
                                                            
                                                    <div className="agency-partner-rate-table role-status-outer w-100 col-md-12 p-sm-t" style={{ display: "inline-block" }}>
                                                        <h2>Referral Fee Configuration</h2>

                                                        <div className='row' style={{ display: elm['partner_id'] === 0 ? "none" : "" }}>
                                                            <div className='col-md-9'>
                                                                <ul>
                                                                    <li><img src={getPartnerLogo(elm?.partner_id)} style={{ width: "100px", height: "50px" }} alt=""/></li>
                                                                    <li><span>{getPartnerName(elm?.partner_id)}</span></li>
                                                                    <li>Minimum Base Rate <span>{elm.min_base_rate}%</span></li>
                                                                    <li>Maximum Base Rate <span>{elm.max_base_rate}%</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        {
                                                            elm?.referralFeeConfig?.map((el: any, childKey: number) =>

                                                                <div className="referral-fee" key={"childForm" + (childKey + 1)}>
                                                                    <div className="row">

                                                                        <div className="col-md-2">
                                                                            <fieldset className="">
                                                                                <div className="material">
                                                                                    <input id="min_base_rate" type="text" placeholder=" " name="min_rate_range" className="form-input" value={el.min_rate_range}
                                                                                        onChange={(e: any) => handleChange('min_rate_range', e, parentKey, childKey)}
                                                                                    />
                                                                                    <label data-label="Issue Min Rate Range*" className="form-label"></label>
                                                                                    <span className="error-txt">
                                                                                        {partnerErrFields?.[parentKey]?.referralSchemeErr?.[childKey]?.['min_rate_range']}
                                                                                    </span>
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>

                                                                        <div className="col-md-2">
                                                                            <fieldset className="">
                                                                                <div className="material">
                                                                                    <input id="max_rate_range" type="text" placeholder=" " name="max_rate_range" className="form-input" value={el.max_rate_range}
                                                                                        onChange={(e: any) => handleChange('max_rate_range', e, parentKey, childKey)}

                                                                                    />
                                                                                    <label data-label="Issue Max Rate Range*" className="form-label"></label>
                                                                                    <span className="error-txt">
                                                                                        {partnerErrFields?.[parentKey]?.referralSchemeErr?.[childKey]?.['max_rate_range']}
                                                                                    </span>
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>

                                                                        <div className="col-md-2">
                                                                                <ReactSelect
                                                                                    options={getPartnerReferralSchemeOptions(parentKey, childKey)}
                                                                                    value={
                                                                                        commonState?.common?.referral_fee_scheme?.find((pt: any) => pt.value === el['referral_fee_scheme']) || null
                                                                                    }
                                                                                    handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e, parentKey, childKey)}
                                                                                    placeholder="Referral Fee Schemes*"
                                                                                    fieldKey="referral_fee_scheme"
                                                                                    name="referral_fee_scheme"
                                                                                    errorField={partnerErrFields?.[parentKey]?.referralSchemeErr?.[childKey]?.['referral_fee_scheme']}
                                                                                />
                                                                        </div>
                                                                        
                                                                        {
                                                                            [1].includes(+el.referral_fee_scheme) ? (
                                                                                <div className="col-md-2">
                                                                                    <fieldset className="">
                                                                                        <div className="material">
                                                                                            <input
                                                                                                id="referralFeePercentage"
                                                                                                type="text"
                                                                                                placeholder=" "
                                                                                                name="referral_fee_percentage"
                                                                                                className="form-input"
                                                                                                value={el.referral_fee_percentage||''}
                                                                                                onChange={(e: any) => handleChange('referral_fee_percentage', e, parentKey, childKey)}
                                                                                            />
                                                                                            <label data-label="Referral Fee Percentage*" className="form-label"></label>
                                                                                            <span className="error-txt">
                                                                                                {partnerErrFields?.[parentKey]?.referralSchemeErr?.[childKey]?.['referral_fee_percentage']}
                                                                                            </span>
                                                                                        </div>
                                                                                    </fieldset>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="col-md-2">
                                                                                    <fieldset className="">
                                                                                        <div className="material">
                                                                                            <input
                                                                                                id="mobileNo"
                                                                                                type="text"
                                                                                                placeholder=" "
                                                                                                name="net_rate_value"
                                                                                                className="form-input"
                                                                                                value={el.net_rate_value || ""}
                                                                                                onChange={(e: any) => handleChange('net_rate_value', e, parentKey, childKey)}
                                                                                            />
                                                                                            <label data-label="Net Rate Value*" className="form-label"></label>
                                                                                            <span className="error-txt">
                                                                                                {partnerErrFields?.[parentKey]?.referralSchemeErr?.[childKey]?.['net_rate_value']}
                                                                                            </span>
                                                                                        </div>
                                                                                    </fieldset>
                                                                                </div>
                                                                            )
                                                                        }

                                                                        

                                                                        {
                                                                            [3,4].includes(+el.referral_fee_scheme) &&
                                                                            <div className="col-md-2">
                                                                                <fieldset className="">
                                                                                    <div className="material">
                                                                                        <input id="mobileNo" type="text" placeholder=" " name="bi_pd_adjustment" className="form-input" value={+el.bi_pd_adjustment||''}
                                                                                            onChange={(e: any) => handleChange('bi_pd_adjustment', e, parentKey, childKey)}
                                                                                        />
                                                                                        <label data-label="BI/PD Adjustment*" className="form-label"></label>
                                                                                        <span className="error-txt">
                                                                                            {partnerErrFields?.[parentKey]?.referralSchemeErr?.[childKey]?.['bi_pd_adjustment']}
                                                                                        </span>
                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>
                                                                        }

                                                                        <div className="col-md-1">
                                                                            <span className="pointer remove-scheme" onClick={() => !props.disableField && setShowConfirmation({ parentKey, childKey, action: true })}>X</span>
                                                                        </div>

                                                                        {/* <div className='col-md-12'>

                                                                        <label>Issue Min Rate Range</label>
                                                                        <input type="text" value="1" />

                                                                        <label>Issue Max Rate Range</label>
                                                                        <input type="text" value="1.5" />

                                                                        <label>Referral Fee Schemes</label>
                                                                        <select>
                                                                            <option>ATB</option>
                                                                        </select>

                                                                        <label>Net Rate Value</label>
                                                                        <input type="text" value="1.5" />

                                                                        <label>BI/PD Adjustment</label>
                                                                        <input type="text" value="5%" />
                                                                    </div> */}

                                                                    </div>
                                                                </div>

                                                            )
                                                        }

                                                        <div className="col-md-2">
                                                            <span className='pointer add-new-scheme' style={{ color: props.disableField ? '#7e7c85' : '' }} onClick={() => !props.disableField && addNewRange(parentKey)}>+ Add New Range</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* ) */}
                                                {/* } */}

                                                <div className="btn-bottom t-center"><button type="submit" className="btn-primary" disabled={props.disableField || !props.agency_id}>Submit</button></div>
                                                {/* <button type="submit" className="btn-primary m-md-t" >Submit</button> */}
                                            </AccordionItemPanel>
                                        </AccordionItem>





                                    </Accordion>


                                </form>

                            </div>
                        )}
                </div>
                {showConfirmation?.action === false ? ("") : (
                    <ConfirmPopup
                        confirmNo={confirmNo}
                        confirmYes={confirmYes}
                        message={"are you sure you want to remove this config?"} />
                )}
            </div>
            {
                commonState?.common?.vehicle_type?.length !== partnerMapping?.length &&
                <div className="t-center p-lg-t">
                    <button type="button" className="btn-primary m-md-t" onClick={addNewPartnerMapping} disabled={props.disableField || !props.agency_id}>+ Add New Mapping</button>
                </div>
            }

            {/* <button type="submit" className="btn-primary m-md-t" onClick={addNewPartnerMapping}>Add Insurance Partner</button> */}
        </>
    )
}