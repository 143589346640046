import React, { useEffect, useState } from 'react'
import InputField from "@src/common/InputField";
import { useSelector,useDispatch } from 'react-redux';
import {WITHOUT_RULE_ENGINE, INS_TYPE_COMPREHENSIVE, APP_TYPE_STANDALONE, WITH_RULE_ENGINE, REGEX_DECIMAL_VALIDATION, MIN_DISCOUNT, MAX_DISCOUNT_PERCENT, APP_TYPE_WITH_UCF, APP_TYPE_RENEWAL, APP_TYPE_AGENCY, REGEX_DECIMAL_VALIDATION_8} from '@src/config/constant';
import { fetchMasterRates } from '@src/store/slices/common';
import { toast } from 'react-toastify';

const QuotesDetail = ({ insuranceType, leadDetails, detail,showApproveBtn,saveInsurance,parentchangeHandler}: any) => {
    const [rates, setRates] = useState<any>({})
    const [addOnRate, setAddonRate] = useState<number>(detail?.base_issue_rate)
    const [inputField, setInputField] = useState<any>({})


    const dispatch: any = useDispatch()
    const commonState = useSelector((state: any) => state.common)

    let showAddOnIncrDecrBtn = (leadDetails?.insurance_type_id === INS_TYPE_COMPREHENSIVE && leadDetails?.rule_engine_id === WITH_RULE_ENGINE && leadDetails?.application_type_id !== APP_TYPE_AGENCY) ? true : false

    const changeHandler =(e:any,item:any)=>{
        let name = e?.target?.name
        let value = e?.target?.value
        const regex = (name === 'add_on_rate' && leadDetails?.application_type_id == APP_TYPE_AGENCY) ? REGEX_DECIMAL_VALIDATION_8 : REGEX_DECIMAL_VALIDATION
        value = (name === 'add_on_rate' && +value === 0) ? value : value.toString().replace(/^0+/, '')
        if(value && name === 'add_on_rate' && leadDetails?.application_type_id == APP_TYPE_AGENCY && ''+value?.split('.')?.[1]?.length > '8') {
            value = parseFloat(value).toFixed(8)
        }
        e.target.value = value
        if (regex.test(value) || value === 0) {
            let data: any = { [name]: +value }
            let addonrate = addOnRate

            if (name === 'discount') {
                value = value === '' ? 0 : value
                setInputField({ discount: value })
                //return if value is single input eg: 5, 9, 8 , needs to be double eg: 10, 11, 12
                if ((value !== 0 || value !== '') && value.length < 2) {
                    return
                }
                //validation for discount
                let maxDiscount = detail['net_premium'] * (MAX_DISCOUNT_PERCENT)
                if (value > maxDiscount) {
                    setInputField({ discount: inputField.discount || detail['discount'] })
                    return toast.error(`Discount Amount should be in between PHP ${MIN_DISCOUNT} & PHP ${Math.floor(maxDiscount)}`)
                }

                //update data with latest net premium & referrer mark up
                data['net_premium'] = detail['net_premium']
                data['referrer_mark_up'] = detail['referrer_mark_up'] || 0
            }
            //IF UCF, then calculate with updated net premium and referrer mark up
            if (leadDetails?.application_type_id === APP_TYPE_WITH_UCF) {
                let fieldName = name === 'net_premium' ? 'referrer_mark_up' : 'net_premium'
                data = { ...data, [fieldName]: detail[fieldName] }
            }
            
            //reset discount on net premium change
            if([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(leadDetails?.application_type_id)) {
                if(name === 'net_premium') inputField['discount'] = 0 
            }

            if (name === 'add_on_rate' && leadDetails?.application_type_id === APP_TYPE_AGENCY) {
                if (!value) {
                    setAddonRate(value)
                    return
                }

                if (value > rates?.basic_rate_max || value < rates?.basic_rate_min) {
                    if([APP_TYPE_AGENCY].includes(leadDetails?.application_type_id) && (+value === rates?.basic_rate_min)) {
                        toast.error(`Base issue rate should be greater than ${rates?.basic_rate_min} and less than equal to ${rates?.basic_rate_max}`)

                    }else{
                        toast.error(`Base issue rate should be between ${rates.basic_rate_min}-${rates.basic_rate_max}`)

                    }

                    return
                }

                addonrate = +value
            }

            parentchangeHandler({...data, add_on_rate: addonrate});
        }
    }

    const handleApply = () => {
        const syntheticEvent = {
            target: {
                name: "add_on_rate",
                value: addOnRate,
            },
        };
        changeHandler(syntheticEvent, detail);
    }

    useEffect(() => {
        if (showAddOnIncrDecrBtn || leadDetails?.application_type_id === APP_TYPE_AGENCY) {
            if (commonState && !Object.keys(commonState?.rates).length) {
                //api call to fetch rates 
                dispatch(fetchMasterRates({}))
                    .then((res: any) => {
                        manageRates(res)
                    })
                    .catch((err: Error) => {
                        setRates({})
                    })
            }
            else manageRates(commonState?.rates)
        }
        // eslint-disable-next-line
    }, [commonState, dispatch, showAddOnIncrDecrBtn])

    useEffect(() => {
        if (detail?.base_issue_rate) {            
            setAddonRate(detail.base_issue_rate)

            if (leadDetails?.application_type_id === APP_TYPE_AGENCY) {
                setRates({ basic_rate_min: +detail?.basic_rate_min || 0, basic_rate_max: +detail?.basic_rate_max || 0 })
            }
        }
        // eslint-disable-next-line
    }, [detail])

    const manageRates = (rates: any) => {
        if (leadDetails?.application_type_id === APP_TYPE_AGENCY) {
            const data = rates?.base_issue_rate_agency?.find((ele: any) => +ele.partner_id === +leadDetails?.partner_id)
            setRates({ basic_rate_min: +data?.min_rate || 0, basic_rate_max: +data?.max_rate || 0 })
            return
        }
        
        rates = rates?.master
        let standaloneRates: any = {}
        for (let data of rates) {
            standaloneRates = {
                ...standaloneRates,
                [data.slug]: +data.value
            }
        }
        setRates(standaloneRates)
        // let updatedLeadAonRate = (leadDetails.application_type_id === 1) ? standaloneRates?.app_with_ucf : standaloneRates?.standalone;
        // setAddonRate(updatedLeadAonRate)
    }



    const handleAddOnIncrDecrClick = (type: string) => {
        let key = leadDetails?.application_type_id === APP_TYPE_WITH_UCF ? '_ucf' : '_standalone';
        let value = type === 'add' ? addOnRate + rates?.[`basic_rate_incement${key}`] : addOnRate - rates?.[`basic_rate_incement${key}`]
        value = +parseFloat(value).toFixed(2);

        if (value < rates?.basic_rate_min) {
            toast.error("Base issue rate should not be less than " + rates?.[`basic_rate_min${key}`])
        } else if (value > rates?.basic_rate_max) {
            toast.error("Base issue rate should not be greater than " + rates?.[`basic_rate_max${key}`])
        }
        else {
            let changeObj: any = { add_on_rate: value } 
            if ((inputField?.['discount'] || detail?.['discount']) > 0) {
                inputField['discount'] = 0 //on rate increment/decrement reset discount
                changeObj['discount'] = 0
            }

            //add referrer mark up if exists when increasing/decreasing addonrate
            if(detail?.['referrer_mark_up']) {
                changeObj['referrer_mark_up'] = detail.referrer_mark_up
            }

            setAddonRate(value)
            parentchangeHandler(changeObj)
        }
    }

    return (
        <div className="loan-form-filed-scroll">

            <div className="loan-filed-sec">

                <div className="form-group-bx">
                    <div className='row align-center'>
                        <div className='col-md-6'>
                            <h3>Insurance Policy Loan Quotation</h3>

                        </div>
                        <div className='col-md-6'>
                            {
                                showApproveBtn && showAddOnIncrDecrBtn
                                ?
                                <div className='add-on-rate-quote-details p-md-b'>
                                    <span> {"Base Issue Rate(Incremental by " + rates?.['basic_rate_incement' + (leadDetails?.application_type_id === APP_TYPE_STANDALONE ? '_standalone' : (leadDetails?.application_type_id === APP_TYPE_WITH_UCF ? '_ucf' : '_renewal'))] + "%) "}</span>
                                    <button className="ic-remove m-sm-r" onClick={() => handleAddOnIncrDecrClick('remove')}></button>
                                    <button className="ic-add" onClick={() => handleAddOnIncrDecrClick('add')}></button>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    <div className="row">
                        {
                            leadDetails?.application_type_id === APP_TYPE_AGENCY
                                && showApproveBtn && leadDetails?.insurance_type_id === INS_TYPE_COMPREHENSIVE
                                ?
                                <>
                                    <fieldset className="form-filed col-md-6">
                                        <div className="material">
                                            <input
                                                id="add_on_rate"
                                                name="add_on_rate"
                                                className="form-input"
                                                type="number"
                                                value={addOnRate}
                                                disabled={false}
                                                // onChange={(showApproveBtn) ? (e: any) => changeHandler(e, detail) : undefined}
                                                onChange={(e: any) => setAddonRate(e.target.value)}
                                                // onBlur={() => {
                                                //     setAddonRate(detail?.base_issue_rate)
                                                // }}
                                            />
                                            <label data-label="Base issue rate" className="form-label"></label>
                                        </div>
                                    </fieldset>
                                    <fieldset className="form-filed col-md-6">
                                        <button type="button" className="btn-primary" style={{ minWidth: '81px' }} onClick={() => handleApply()}>Apply</button>
                                    </fieldset>
                                </>
                                : null
                        }
                        <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <InputField
                                    inputProps={{
                                        id: "total_fmv",
                                        name: "total_fmv",
                                        className: "form-input",
                                        type: "text",
                                        value: (detail)? detail.total_fmv :'',
                                        maxLength: '',
                                        disabled: true
                                    }}
                                    onChange={''}
                                />
                                <label data-label="FMV" className="form-label"></label>
                            </div>
                        </fieldset>
                        {
                            leadDetails && leadDetails?.application_type_id === APP_TYPE_WITH_UCF
                                ?
                                <fieldset className="form-filed col-md-6">
                                    <div className="material">
                                        <InputField
                                            inputProps={{
                                                id: "proposed_fmv",
                                                name: "proposed_fmv",
                                                className: "form-input",
                                                type: "text",
                                                value: (detail) ? detail.proposed_fmv : '',
                                                maxLength: '',
                                                disabled: true
                                            }}
                                            onChange={''}
                                        />
                                        <label data-label="Proposed FMV" className="form-label"></label>
                                    </div>
                                </fieldset>
                                : null
                        }
                        <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <InputField
                                    inputProps={{
                                        id: "own_theft",
                                        name: "own_theft",
                                        className: "form-input",
                                        type: "text",
                                        value: (detail)? detail.own_theft_damage :'', 
                                        maxLength: '',
                                        disabled: true
                                    }}
                                    onChange={''}
                                />
                                <label data-label="Own Theft Damage" className="form-label"></label>
                            </div>
                        </fieldset>
                        {
                            detail && detail.bi_coverage ? 
                                <fieldset className="form-filed col-md-6">
                                    <div className="material">
                                        <InputField
                                            inputProps={{
                                                id: "bi_coverage_amount",
                                                name: "bi_coverage_amount",
                                                className: "form-input",
                                                type: "text",
                                                value: (detail && detail.bi_coverage) || '0 ',  
                                                maxLength: '',
                                                disabled: true
                                            }}
                                            onChange={''}
                                        />
                                        <label data-label="Body Injury Coverage Amount" className="form-label"></label>
                                    </div>
                                </fieldset>
                            : null

                        }
                        
                        <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <InputField
                                    inputProps={{
                                        id: "bi_amt",
                                        name: "bi_amt",
                                        className: "form-input",
                                        type: "text",
                                        value: (detail && detail.bi_amt) || '0 ',  
                                        maxLength: '',
                                        disabled: true
                                    }}
                                    onChange={''}
                                />
                                <label data-label="Body Injury Premium" className="form-label"></label>
                            </div>
                        </fieldset>
                        {
                            detail && detail.pd_coverage  ?
                                <>
                                    {
                                        (detail && detail.pd_coverage) ?
                                            <fieldset className="form-filed col-md-6">
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                            id: "pd_coverage_amount",
                                                            name: "pd_coverage_amount",
                                                            className: "form-input",
                                                            type: "text",
                                                            value: (detail && detail.pd_coverage) || '0 ',  
                                                            maxLength: '',
                                                            disabled: true
                                                        }}
                                                        onChange={''}
                                                    />
                                                    <label data-label="Property Damage Coverage Amount" className="form-label"></label>
                                                </div>
                                            </fieldset>
                                        : null
                                    }
                                    
                                    <fieldset className="form-filed col-md-6">
                                        <div className="material">
                                            <InputField
                                                inputProps={{
                                                    id: "pd_amt",
                                                    name: "pd_amt",
                                                    className: "form-input",
                                                    type: "text",
                                                    value: detail.pd_amt || '0 ',
                                                    maxLength: '',
                                                    disabled: true
                                                }}
                                                onChange={''}
                                            />
                                            <label data-label="Property Damage Premium" className="form-label"></label>
                                        </div>
                                    </fieldset>
                                </>
                                : null
                        }
                        {
                            detail && detail.pa_coverage ?

                                <>
                                    {
                                        (detail && detail.pa_coverage)
                                        ?
                                            <fieldset className="form-filed col-md-6">
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                            id: "pa_coverage_amount",
                                                            name: "pa_coverage_amount",
                                                            className: "form-input",
                                                            type: "text",
                                                            value: (detail && detail.pa_coverage) || '0 ',  
                                                            maxLength: '',
                                                            disabled: true
                                                        }}
                                                        onChange={''}
                                                    />
                                                    <label data-label="Personal Accident Coverage Amount" className="form-label"></label>
                                                </div>
                                            </fieldset>
                                        : null

                                    }
                                    

                                    <fieldset className="form-filed col-md-6">
                                        <div className="material">
                                            <InputField
                                                inputProps={{
                                                    id: "pa_amt",
                                                    name: "pa_amt",
                                                    className: "form-input",
                                                    type: "text",
                                                    value: detail.pa_amt || '0 ' ,
                                                    maxLength: '',
                                                    disabled: true
                                                }}
                                                onChange={''}
                                            />
                                            <label data-label="Personal Accident" className="form-label"></label>
                                        </div>
                                    </fieldset> 
                                
                                </>
                                : null
                        }

                        {
                            (detail?.total_coverage_amount_pa && detail?.pa_coverage) ?
                            <fieldset className="form-filed col-md-6">
                                <div className="material">
                                    <InputField
                                        inputProps={{
                                            id: "total_coverage_amount_pa",
                                            name: "total_coverage_amount_pa",
                                            className: "form-input",
                                            type: "text",
                                            value: detail.total_coverage_amount_pa || '0 ',
                                            maxLength: '',
                                            disabled: true
                                        }}
                                        onChange={''}
                                    />
                                    <label data-label="Total Coverage Amount (PA)" className="form-label"></label>
                                </div>
                            </fieldset> : null
                        }

                        

                        {
                            detail && detail.tra_amt ?
                                <fieldset className="form-filed col-md-6">
                                    <div className="material">
                                        <InputField
                                            inputProps={{
                                                id: "tra_amount",
                                                name: "tra_amount",
                                                className: "form-input",
                                                type: "text",
                                                value: detail.tra_amt || '0 ' ,
                                                maxLength: '',
                                                disabled: true
                                            }}
                                            onChange={''}
                                        />
                                        <label data-label="Towing Roadside Assistance" className="form-label"></label>
                                    </div>
                                </fieldset> : null
                        }

                        {
                            detail && detail.hasOwnProperty('rscc_amount') ?
                                <fieldset className="form-filed col-md-6">
                                    <div className="material">
                                        <InputField
                                            inputProps={{
                                                id: "rscc_amount",
                                                name: "rscc_amount",
                                                className: "form-input",
                                                type: "text",
                                                value: detail.rscc_amount || 0,
                                                maxLength: '',
                                                disabled: true
                                            }}
                                            onChange={''}
                                        />
                                        <label data-label="Riot, Strikes, and Civil Commotion (RSCC)" className="form-label"></label>
                                    </div>
                                </fieldset>
                                : null
                        }

                        {
                            detail && detail.hasOwnProperty('bi_pd_pa')  ?
                                <fieldset className="form-filed col-md-6">
                                    <div className="material">
                                        <InputField
                                            inputProps={{
                                                id: "bi_pd_pa",
                                                name: "bi_pd_pa",
                                                className: "form-input",
                                                type: "text",
                                                value: detail.bi_pd_pa || '0 ',
                                                maxLength: '',
                                                disabled: true
                                            }}
                                            onChange={''}
                                        />
                                        {/* <label data-label={detail.tra_amt ? "BI + PD + PA + TRA" : "BI + PD + PA"} className="form-label"></label> */}
                                        <label data-label={detail.rscc_amount ? 'BI + PD + PA + RSCC' : "BI + PD + PA"} className="form-label"></label>
                                    </div>
                                </fieldset>
                                : null

                        }
                      
                      {
                        detail && detail.hasOwnProperty('aon_rate') && +insuranceType === 1 ? 
                            <fieldset className="form-filed col-md-6">
                                <div className="material">
                                    <InputField
                                        inputProps={{
                                            id: "aon_rate",
                                            name: "aon_rate",
                                            className: "form-input",
                                            type: "text",
                                            value: detail.aon_status || leadDetails.act_of_nature_included,
                                            maxLength: '',
                                            disabled: true
                                        }}
                                        onChange={''}
                                    />
                                    <label data-label="Acts Of Nature" className="form-label"></label>
                                </div>
                            </fieldset> 
                            : null 
                        }


                        <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <input 
                                    id="net_premium" 
                                    name="net_premium"
                                    className="form-input"
                                    type="number" 
                                    value={(detail)? detail.net_premium : ''}
                                    disabled={(showApproveBtn && (+leadDetails?.rule_engine_id === WITHOUT_RULE_ENGINE && +insuranceType === INS_TYPE_COMPREHENSIVE ) )?false:true}
                                    onChange={(showApproveBtn && (+leadDetails?.rule_engine_id === WITHOUT_RULE_ENGINE && +insuranceType === INS_TYPE_COMPREHENSIVE ) ) ? (e:any)=>changeHandler(e,detail) : undefined} />
                                <label data-label="Net Premium" className="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <InputField
                                    inputProps={{
                                        id: "vat",
                                        name: "vat",
                                        className: "form-input",
                                        type: "text",
                                        value: (detail) ? detail.vat : '',
                                        maxLength: '',
                                        disabled: true
                                    }}
                                    onChange={''}
                                />
                                <label data-label="VAT" className="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <InputField
                                    inputProps={{
                                        id: "stamp_duty",
                                        name: "stamp_duty",
                                        className: "form-input",
                                        type: "text",
                                        value: (detail) ? detail.stamp_duty : '',
                                        maxLength: '',
                                        disabled: true
                                    }}
                                    onChange={''}
                                />
                                <label data-label="Stamp Duty" className="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <InputField
                                    inputProps={{
                                        id: "local_govt_taxes",
                                        name: "local_govt_taxes",
                                        className: "form-input",
                                        type: "text",
                                        value: (detail)? detail.local_govt_taxes : '',
                                        maxLength: '',
                                        disabled: true
                                    }}
                                    onChange={''}
                                />
                                <label data-label="Local Govt taxes" className="form-label"></label>
                            </div>
                        </fieldset>
                        {
                            detail?.tariff > 0 &&
                            <fieldset className="form-filed col-md-6">
                                <div className="material">
                                    <InputField
                                        inputProps={{
                                            id: "tariff",
                                            name: "tariff",
                                            className: "form-input",
                                            type: "text",
                                            value: (detail)? detail.tariff : '',
                                            maxLength: '',
                                            disabled: true
                                        }}
                                        onChange={''}
                                        />
                                    <label data-label="Tariff" className="form-label"></label>
                                </div>
                            </fieldset>
                        }


                        <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <InputField
                                    inputProps={{
                                        id: "gross_premium",
                                        name: "gross_premium",
                                        className: "form-input",
                                        type: "text",
                                        value: (detail)?  detail.gross_premium : '',
                                        maxLength: '',
                                        disabled: true
                                    }}
                                    onChange={''}
                                />
                                <label data-label="Gross Premium" className="form-label"></label>
                            </div>
                        </fieldset>
                        {
                            detail && detail.hasOwnProperty('referrer_mark_up') && leadDetails?.application_type_id !== APP_TYPE_AGENCY ?
                                <fieldset className="form-filed col-md-6">
                                    <div className="material">
                                        <input
                                            id="referrer_mark_up"
                                            name="referrer_mark_up"
                                            className="form-input"
                                            type="number"
                                            value={detail.referrer_mark_up || 0}
                                            disabled={showApproveBtn ? false : true}
                                            onChange={(showApproveBtn) ? (e: any) => changeHandler(e, detail) : undefined}
                                        />
                                        <label data-label="Referrer Mark Up" className="form-label"></label>
                                    </div>
                                </fieldset>
                                : null
                        }

                        <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <InputField
                                    inputProps={{
                                        id: "final_premium",
                                        name: "final_premium",
                                        className: "form-input",
                                        type: "text",
                                        value: (detail) ? detail.final_premium : '',
                                        maxLength: '',
                                        disabled: true
                                    }}
                                    onChange={''}
                                />
                                <label data-label="Final Premium" className="form-label"></label>
                            </div>
                        </fieldset>

                        {
                            [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(leadDetails.application_type_id) ?
                                <>
                                    {
                                        detail && detail.hasOwnProperty('discount') && <fieldset className="form-filed col-md-6">
                                            <div className="material">
                                                <input
                                                    id="discount"
                                                    name="discount"
                                                    className="form-input"
                                                    type="number"
                                                    value={(inputField.discount || inputField.discount === 0) ? inputField.discount : detail.discount}
                                                    disabled={(showApproveBtn && [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(leadDetails?.application_type_id)) ? false : true}
                                                    onChange={(showApproveBtn && [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(leadDetails?.application_type_id)) ? (e: any) => changeHandler(e, detail) : undefined}
                                                />
                                                <label data-label="Discount to Customer" className="form-label"></label>
                                            </div>
                                        </fieldset>
                                    }
                                    {
                                        (detail && detail.hasOwnProperty('total_premium') && <fieldset className="form-filed col-md-6">
                                            <div className="material">
                                                <InputField
                                                    inputProps={{
                                                        id: "total_premium",
                                                        name: "total_premium",
                                                        className: "form-input",
                                                        type: "text",
                                                        value: inputField.discount ? (detail['final_premium'] - inputField.discount).toFixed(2) : detail.total_premium,
                                                        maxLength: '',
                                                        disabled: true
                                                    }}
                                                    onChange={''}
                                                />
                                                <label data-label="Total Premium" className="form-label"></label>
                                            </div>
                                        </fieldset>)
                                    }

                                </>
                                : <></>

                        }
                    </div>
                    <div className={(showApproveBtn)? "show text-al-r":"hide text-al-r"}>
                    <button type="button" className="btn-primary" onClick={() => {
                        if(inputField?.discount !== 0 && inputField?.discount?.toString()?.length < 2) {
                            return toast.error('Please enter a valid discount amount')
                        }
                        if(!addOnRate && leadDetails?.insurance_type_id === INS_TYPE_COMPREHENSIVE) {
                            return toast.error('Please enter valid base issue rate')
                        }
                        saveInsurance()
                    }}>Approve</button>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default QuotesDetail